import Vue from 'vue';
import Vuex from 'vuex';
import state from "./state";
import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";
//import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

let vuex = new Vuex.Store({
    state,
    getters,
    actions,
    mutations,
    //plugins: [createPersistedState()],
})

export default vuex