<template>
  <div class="second-level">
    <header>
      <div class="sidemenu" @click="$emit('secondLevel','')">
        <div class="sidemenu__btn">
          <span class="top"></span>
          <span class="bottom"></span>
        </div>
      </div>

      关于我们
    </header>
    <div class="content-wrapper">
      <p>
        百万富翁资讯网是一家致力于中国及世界各地公益彩票事业，提供全方位彩票开奖及彩票相关服务的提供商。
      </p>
      <p>
        百万富翁资讯网以互联网技术、电信技术、无线应用技术为依托，将创新的技术与传统的彩票产业相结合，面向全球彩票服务市场，提供最快最全的彩票开奖相关服务，包括彩票开奖结果调用、彩票系统的研发、彩票开奖移动客户端，及彩票产品创新等。
      </p>
      <p>
        百万富翁资讯网拥有一流的技术研发创新实力以及国内外多年的彩票网站运营及管理经验， 为中国以及全球彩票玩家提供一流的彩票创新产品及优质的服务。百万富翁资讯网本着专业、诚信、服务至上的经营理念， 充分发挥机动灵活的运营及管理机制，伴随着世界互联网及手机互联网时代的腾飞、彩票市场的不断繁荣，和各方客户的忠诚支持，将全面促进新型彩票服务市场的全盛发展。
      </p>
    </div>
  </div>

</template>

<script>

export default {
  name: 'SecondLevelAbout'
}
</script>
<style scoped lang="scss">
.second-level{
  position:fixed;
  z-index:3;
  width:100%;
  background: #f6f5f5;
  overflow-y:auto;
  height:calc(100% - 80px);

}
header{
  height: 50px;
  width: 100%;
  background: url('~@/img/H5/top_bg.jpg');
  top:0;
  position:fixed;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  font-weight: 700;
  font-size: 16px;
  color: #000;
}
.content-wrapper{
  padding-top:70px;
  margin:0 15px;


}
p{
  font-size: 10px !important;
  line-height: 2 !important;
  margin-bottom: 8px;
  color:#7d7d7d;
}

.sidemenu {
  &__btn {
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    width: 50px;
    height: 50px;
    border: none;
    z-index: 100;
    appearance: none;
    cursor: pointer;
    outline: none;

    span {
      display: block;
      width: 12px;
      height: 2px;
      margin: auto;
      background: #000;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    left: 0;
    .top {
      transform: rotate(-40deg);
      top: -7px;
    }

    .bottom {
      transform: rotate(40deg);
      top: 7px;
    }


  }
}

</style>

