<!--Homepage bottom-->
<template>
    <div class="LotteryCard_type2">
      <div v-if="loaded===false">
        <div class="top">
          <div class="lottery_info">
            <div class="placeholder circle-img" style="width:120px;height:120px;"></div>
            <div class="info">
              <div class="placeholder" style="width:90px;height:22px;margin-top:8px;margin-bottom:4px;"></div>
              <div class="placeholder" style="width:80px;height:15px;margin-bottom:8px;"></div>
              <div class="placeholder" style="width:60px;height:12px;margin-bottom:5px;"></div>
              <div class="placeholder" style="width:190px;height:34px;"></div>
            </div>
          </div>
          <div class="option"></div>
        </div>
        <div class="bottom">
          <div class='title_row' style="display:flex;">
            <div class="placeholder" style="width:130px;height:25px;"></div>
            <div class="placeholder" style="width:110px;height:25px;margin:0 30px;"></div>
            <div class="placeholder" style="width:68px;height:25px;"></div>
          </div>

          <div style="display:flex;width:392px;justify-content: space-around">
            <div class="placeholder circle-img" style="width:50px;height:50px;"></div>
            <div class="placeholder circle-img" style="width:50px;height:50px;"></div>
            <div class="placeholder circle-img" style="width:50px;height:50px;"></div>
            <div class="placeholder circle-img" style="width:50px;height:50px;"></div>
            <div class="placeholder circle-img" style="width:50px;height:50px;"></div>
            <div class="placeholder circle-img" style="width:50px;height:50px;"></div>
          </div>
        </div>
      </div>
      <div v-if="loaded===true">

          <div class="top">
              <div class="lottery_info">
                  <img class="icon" alt='' v-if="lotteryCode" :src="require('@/img/ballIcon/'+imgUrl)">
                  <div class="info">
                      <div class="info_tilte">
                          {{lotteryName}}
                      </div>
                      <div class="draw_number">
                          {{currentperiod.period_no | formatComingLotteryType2(currentperiod.draw_time, lotteryCode, currentperiod.period_no)}}
                      </div>
                     
                      <div class="timer">
                          <span v-if="lotteryDrawTime[lotteryCode] > 0">
                              <span class="value" v-if="isLowOpen == true">{{lotteryDrawTime[lotteryCode] | reciprocalDay}}</span>
                              <span v-if="isLowOpen == true">天</span>
                              <span class="value" v-if="isLowOpen == true">{{lotteryDrawTime[lotteryCode] | reciprocalHour}}</span>
                              <span v-if="isLowOpen == true">时</span>
                              <span class="value">{{lotteryDrawTime[lotteryCode] | reciprocalMinute}}</span>
                              分
                              <span class="value">{{lotteryDrawTime[lotteryCode] | reciprocalSecond}}</span>
                              秒
                          </span>
                          <span v-else>
                               <div class="waitTime text"><img alt='' class="rotating" src="@/img/return-1.png"><span>开奖中...</span></div>
                          </span>
                      </div>
                  </div>

              </div>
              <div class="option">
                  <div v-if='lotteryCode !="kl8lhc"' class="itme" :class="lotteryConfig[lotteryCode].liveStream ? 'active':''"  @click="openLiveStream">
                      <icon name="player" class="icon"></icon>直播回放
                  </div>
                <a class='launch-kl8-website itme active' v-else href='https://www.cwl.gov.cn/ygkj/kjzb/' target='_blank' rel='noopener noreferrer'>
                  <icon name="player" class="icon"></icon>  开奖验证
                </a>
                  <div class="itme active"
                      @click="gotoHistory(key)"
                      v-for="(value,key) in $lotteryConfig[lotteryCode].mainMenu"
                      :key="key"
                      v-if="$lotteryConfig[lotteryCode].mainMenu[key].iconName != ''">
                     <icon :name="$lotteryConfig[lotteryCode].mainMenu[key].iconName" class="icon"></icon>
                     {{$lotteryConfig[lotteryCode].mainMenu[key].name}}
                  </div>
              </div>
          </div>
          <div class="bottom">
              <div class="title_row">
                  <i class="icon">
                    <img class="star" src="@/img/star.png"> 
                  </i>
                  <span class="period"><span class="gray">第</span> {{lastperiod.period_no}} <span class="gray">期</span></span>
                    <div class="item-detail" v-if='lotteryConfig[lotteryCode].lotteryType==1'>
                        
                        <span class="drawdate" v-if="lotteryCode==='xglhc'">每周二、四、六  21:30:00开奖 </span>
                        <span class="drawdate" v-if="lotteryCode==='qxc'">每周二、五、日  21:30:00开奖 </span>
                        <span class="drawdate" v-if="lotteryCode==='lucky7daily'">每日22:00:00开奖 </span>
                        <span class="drawdate" v-if="lotteryCode==='pl3'">每日21:30:00开奖 </span>
                        <span class="drawdate" v-if="lotteryCode==='pl5'">每日21:30:00开奖 </span>
                        <span class="drawdate" v-if="lotteryCode==='pl5daily'">每日21:00:00开奖 </span>
                        <span class="drawdate" v-if="lotteryCode==='kl8lhc'">每日21:30:00开奖 </span>
                    </div>
                    <div v-else class="item-detail">当前<span> {{drawCount}} </span>期,剩<span> {{totalCount-drawCount}} </span>期</div>
              </div>
              <!-- 六合彩 --->
              <LhcBall
                  v-if="lotteryCode=='xglhc'||lotteryCode=='kl8lhc'"
                  :lastDraw="lastDraw"
                  :lotteryCode="lotteryCode"/>
              <!-- 一般彩種 --->
              <DefaultBall
                  v-else
                  :lastDraw="lastDraw"
                  :lotteryCode="lotteryCode"  />

              <div class="table">
                  <LhcTable :lastperiod="lastperiod" v-if="lotteryCode=='xglhc'||lotteryCode=='kl8lhc'"/>
                  <QxcTable :lastperiod="lastperiod" v-if="lotteryCode=='qxc'"/>
              </div>

          </div>
        </div>
      </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import {lotteryConfig} from "@/assets/lotteryConfig.js";
import LhcBall from "@/components/common/lotteryCard/lotteryCardType2/lhc_ball";
import DefaultBall from "@/components/common/lotteryCard/lotteryCardType2/default_ball";
import LhcTable from "@/components/common/lotteryCard/lotteryCardType2/lhc_table";
import QxcTable from "@/components/common/lotteryCard/lotteryCardType2/qxc_table";
export default {
    name: 'LotteryCard_type2',
    components:{
        LhcBall,
        DefaultBall,
        LhcTable,
        QxcTable
    },
    props: {
        msg: String,
        lotteryCode:String,
        imgUrl:String,
    },
    data() {
        return {
            timerCountDown: 0,
            lotteryName: "",
            currentperiod: {},
            lastDraw: [],
            lastperiod: {},
            lotteryConfig: lotteryConfig,
            isShowPeriod: false,
            loaded: false,
            drawCount: 0,
            totalCount: 0,

        }
    },
    computed: {
        ...mapState([
            "baseInfo",
            "lotteryDrawTime"
        ]),
        isForeign: function(){
          return this.lotteryConfig[this.lotteryCode].displayperiodType == 'foreign'
        },
        isLowOpen: function(){
          return this.lotteryConfig[this.lotteryCode].lotteryType == 1
      }
    },
    watch: {
        lotteryDrawTime: {
            handler: function(value){
                if(value[this.lotteryCode]) {
                    let time = value[this.lotteryCode];
                    if(time)
                    this.timerCountDown = time;
                }                
            },
            immediate: true,
            deep: true,
        },
        baseInfo: {
            handler: function(value){
                this.setLotteryInfo(this.lotteryCode);
            },
            immediate: true,
            deep: true,
        },
    },
    methods: {
        setLotteryInfo: function(lotteryCode) {
            if(!this.baseInfo[lotteryCode]) {
                return;
            }

            this.loaded = true;
            let lotteryInfo = this.baseInfo[lotteryCode];
            this.lotteryName = lotteryInfo.name; // 樂透名
            if(lotteryInfo) {
                // 當前資訊
                this.currentperiod = lotteryInfo.current_period; 

                this.drawCount = this.currentperiod.draw_count;
                this.totalCount = this.currentperiod.total_count;
                // 上期資訊
                this.lastperiod = lotteryInfo.last_period;
                
                this.lastDraw = this.$options.filters.splitDrawNumber(this.lastperiod.draw.split(","),this.lotteryCode);
            }                
        },
        gotoHistory(tabName){    
           this.$router.push({ path: `/lottery/${this.lotteryCode}/?tabName=${tabName}` })
        },
        openLiveStream: function(){
            if(!this.lotteryConfig[this.lotteryCode].liveStream ){
                return false;
            } else {
                this.$emit('openVedio', this.lotteryCode, this.baseInfo[this.lotteryCode]);
            }
        }
    },
    mounted() {
        switch(this.lotteryConfig[this.lotteryCode].displayperiodType) {
            case "nationwide":
                this.isShowPeriod = true;
                break;
            case "foreign":
                this.isShowPeriod = false;
                break;
            case "satlotto":
                this.isShowPeriod = true;
                break;
            default:
                break;
        }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.LotteryCard_type2 {
    width: 630px;
    object-fit: contain;
    border-radius: 10px;
    margin-bottom: 20px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;


    .top {
        width: 100%;
        display: flex;



        .lottery_info {
            height: 152px;
            width: 100%;
            background-image: url('~@/img/looter_bg.png');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            border-radius: 10px 0 0 0;
            padding: 16px;
            box-sizing: border-box;
            display: flex;


            .icon {
                width: 120px;
                height: 120px;
            }

            .info {
                display: flex;
                flex-direction: column;
                margin-left: 16px;

                .info_tilte {
                    height: 25px;
                    
                    font-size: 20px;
                    text-align: left;
                    color: #d7b695;
                    line-height: 30px;
                    flex-grow: 1
                }

                .draw_number {
                    height: 17px;
                    font-size: 12px;
                    text-align: left;
                    color: #d7b695;
                    line-height: 17px;
                    flex-grow: 1;
                    margin-bottom: 14px;
                }

                .reciprocal {
                    margin: 10px 0 15px 0;
                    
                    font-size: 12px;
                    text-align: left;
                    color: #7f8993;
                }

                .timer {
                    width: auto;
                    min-width: 130px;
                    padding: 0 10px;
                    height: 33px;
                    border-radius: 4px;
                    background-color: #efeded;
                    text-align: center;
                    line-height: 33px;
                    margin-bottom: 15px;

                    .value {
                        font-size: 18px;
                        color: #09558b;
                        font-weight: bold;
                    }
                    .waitTime{
                        display: flex;
                        justify-content: space-around;
                        align-items: center;
                        display: -webkit-flex;
                        -webkit-align-items: center;
                        img{
                            width: 20px;
                        }
                        span{
                                color:#001c5d;
                                font-size: 20px;
                                font-weight: 500;
                            }
                    }
                }
            }

            .live {
                @media (max-width: $mobile_width) {
                    position: absolute;
                    right: 20px;
                }

                .btn {
                    width: 40px;
                    height: 15px;
                    margin: 1px 0 5px 11px;
                    padding: 2px 8px 1px 7px;
                    border-radius: 20px;
                    border: solid 1px #59626c;
                    border-color: #59626c;
                    color: #59626c;
                    font-size: 12px;
                    text-align: center;
                    line-height: 15px;
                }
            }
        }

        .option {
            width: 190px;
            min-width: 190px;
            height: 152px;
            background-color: #c19971;
            border-radius: 0 10px 0 0;
            box-sizing: border-box;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column-reverse;
            justify-content: space-between;
            align-items: center;
            display: -webkit-flex;
            -webkit-align-items: center;
            justify-content: space-around;

            @media (max-width: $mobile_width) {
                border-radius: 0 0 0 0;
                width: 100%;
                height: auto;
            }

            .itme {
                font-size: 18px;
                font-weight: bold;
                align-items: center;
                -webkit-align-items: center;
                display: flex;
                justify-content: center;
                width: 100%;
                height: 25px;
                background-color: transparent;
                border-radius: unset;
                box-shadow: unset;
                border: unset;
                text-align: center;
                line-height: 24px;
                color: #e4cdb6;
                padding: 37px 0;
                &:nth-child(2){
                  
                    border-bottom: 1px solid #d7b089;
                }
                .icon{
                    width: 24px;
                    height: 24px;
                }
                &.active{
                    color: #623200;
                    cursor: pointer;
                }
            }
        }
    }

    .bottom {
        height: 240px;
        width: 100%;
        padding: 16px 23px 23px;
        box-sizing: border-box;

        .title_row {
            text-align: left;
            color: #001236;
            font-size: 17px;
            margin-bottom: 25px;
            font-family: "Open Sans";
            font-weight: 800;
            display: flex;
            align-items: flex-end;
            .period{
                margin:0 10px;
                font-size:20px;
                .gray{
                   color: #6d6d6d;
                }

            }
            .drawdate,.item-detail{
                font-size:14px;
                color: #868686;
            }
        }

    }
}
.launch-kl8-website{
  font-size: 18px;
  font-weight: bold;
  align-items: center;
  -webkit-align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 25px;
  background-color: #ffc97b!important;
  border-radius: unset;
  box-shadow: unset;
  border: unset;
  text-align: center;
  line-height: 24px;
  color: #e4cdb6;
  padding: 37px 0;
}
.launch-kl8-website .icon{width:24px;height:24px;}
</style>