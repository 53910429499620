<template>
    <div class="ImagesLibExplain">
        <!--- 主選單 --->
        <div class="lottoy_menu_wraper">
            <div class="lottoy_menu">
                <div class="menu_btn">
                    <div class="menu_icon active"><span>{{info.PeriodNo}}</span></div>
                </div>
            </div>
        </div>

        <div class="result">
            <div class="result_view">
                <table>
                    <tr style="height:1%">
                        <td style="width:2%"><span>总彩波色：{{info.ZongCaiBoSe}}</span></td>
                        <td style="width:5%"><span>{{info.PoetryMystery}}</span></td>
                        <td style="width:1%; text-align: center;">特码 波</td>
                        <td style="width:1%; text-align: center;">生肖</td>
                    </tr>
                    <tr style="height:1%">
                        <td class="shaxiao"><span>内幕杀肖：{{info.NeiMuShaXiao}}</span></td>
                        <td rowspan="2"><div>解： {{info.MysteryAnswer}}</div></td>
                        <td rowspan="2" style="text-align: center;">{{info.SpecialDrawNo}} {{info.SpecialBo}}</td>
                        <td rowspan="2" style="text-align: center;">{{info.SpecialXiao}}</td>
                    </tr>
                    <tr style="height:1%">
                        <td><span>生肖玄机：{{info.ShengXiaoXuanJi}}</span></td>
                    </tr>
                </table>
            </div>  
        </div>
    </div>
</template>

<script>

export default {
  name: 'ImagesLibExplain',
  props: {
    info: Object,
  }
}
</script>

<style scoped lang="scss">

.ImagesLibExplain{    
    width: 1180px;
    height: 166px;
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    
    opacity: 1;
    .main{
        width: 1181px;
        height: 166px;
        /* UI Properties */
        opacity: 1;
    }

    
    // lottoy_menu
    .lottoy_menu_wraper{
        max-width: 1440px;
        margin: 20px auto 0 auto;
        box-sizing: border-box;
        position: relative;
        .lottoy_displayAll{
            display: none;  
        }
        .menu_icon{
            width: 24px;
            height: 24px;
            color: red;
        }
        .lottoy_menu{
            display: flex;
            .menu_btn{
                position:absolute;
                width: 140px;
                height: 35px;
                text-align: left;
                font: normal normal bold 16px/21px Segoe UI;
                letter-spacing: 0.61px;
                color: #F1E6C7;
                opacity: 1;
                display: inline-block;
                border-right:1px solid #2abdec;
                text-align:center;
                line-height: 35px;
                background: -webkit-linear-gradient(180deg, rgb(30, 59, 125), rgb(8, 34, 93));
                background: linear-gradient(180deg, rgb(30, 59, 125), rgb(8, 34, 93));

                border-radius: 10px 10px 0 0;
                .menu_icon{
                    color: #f2cba6;
                    vertical-align: text-bottom;
                    margin-right: 5px;
                    &.active{
                        color: #ffdfc7;
                    }
                    span{
                        margin-left: 16px;
                    }
                }
            }
            .active{
                color: #ffdfc7;
            
            }
        }   
    }
    .result{
        margin: 0 auto 20px auto;
        width: 1181px;
        height: 166px;
        box-sizing: border-box;
        .result_view{
            height: 131px;
            margin-top: 35px;
            table{
                height: 100%;                
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 1px solid #A2BDEC;
                opacity: 1;
                font: normal normal normal 16px/21px Segoe UI;
                letter-spacing: 0.61px;
                color: #646363;
                opacity: 1;
                tr, td{
                    border: 1px solid #A2BDEC;
                    opacity: 1;
                }
                td{
                    text-align: left;
                }
                .shaxiao{
                    background: #EBF5FF 0% 0% no-repeat padding-box;
                }
                span{
                    margin-left: 10px;
                }
                div{
                    margin-left: 10px;
                    margin-right: 10px;
                }
            }
        }
    }
}

</style>
