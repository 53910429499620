<template>
  <div class="coming_lottery">
    <div class="title">即将开奖</div>
    <div class="loading-state" v-if="loaded===false" v-for="(el) in new Array(3)">
      <div class="row">
        <div class="placeholder circle-img"></div>
        <div class="lottery_info">
          <div class="placeholder" style="height:25px;width:90px;margin-bottom:5px;"></div>
          <p class="placeholder" style="height:20px;width:80px;"></p>
        </div>
        <div class="timer">
          <div class="placeholder" style="height:15px;width:90px;margin-right:0;margin-left:auto;margin-bottom:5px;"></div>
          <div class="placeholder" style="height:17px;width:105px;margin-right:0;margin-left:auto;margin-bottom:5px;"></div>
          <div class="placeholder" style="height:13px;width:100px;margin-right:0;margin-left:auto;"></div>
        </div>
      </div>
    </div>
    <div v-if="loaded===true">
      <LotteryCardType1
          v-for="(item, index) in comingLottery"
          :key="'coming' + index"
          :lotteryCode="item"
      />
    </div>
  </div>
</template>

<script>
import LotteryCardType1 from "@/components/common/lotteryCard/lotteryCard_type1.vue";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "ComingLottery",
  props: {},
  components: {
    LotteryCardType1,
  },
  computed: {
    ...mapState(["baseInfo"]),
  },
  watch: {
    baseInfo: {
      handler: function (value) {
        this.observeLottery();
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      comingLottery: [],
      loaded: false
    };
  },
  methods: {
    observeLottery() {
      this.comingLottery = this.lotteryUtil.getComingDraw();
      if (this.comingLottery.length > 0) {
        this.loaded = true;
      }
    },
  },
  mounted() {
    this.observeLottery();
  },
};
</script>

<style scoped lang="scss">
.coming_lottery {
  width: 340px;
  height: 100%;

  @media (max-width: $mobile_width) {
    width: 100%;
  }

  .title {
    height: 50px;
    font-size: 20px;
    text-align: left;
    font-weight: 600;
    color: #001236;
    border-bottom: 0.5px #aab2ba solid;
    line-height: 39px;
  }

  .lotteryCard_type1:last-child {
    border-bottom: unset;
  }

  .loading-state{
    margin-top: 10px;
    padding-bottom: 10px;
    display: flex;
    color: #001236;
    border-bottom: 0.5px #aab2ba solid;
    .circle-img{width:55px;height:55px;}
    .row{display:flex;justify-content: space-around;align-items:center;width:308px}
    &:last-child {
      border-bottom: unset;
    }
  }
}
</style>
