<template>
    <div v-if="dataList">
        <div class="header">
            {{dataInfor.dataTitle }}（{{dataLength}}期）
        </div>
        
        <table width="100%" style="table-layout:fixed" v-if="dataList && dataInfor.mapApiKey">
             <!-- 單層 -->
            <tr class="title" v-if="!Array.isArray(dataList[dataInfor.mapApiKey])">
                <td v-for="(item,index) in ['期數'].concat(Object.keys(dataList[dataInfor.mapApiKey]))" :key="index">{{dataInfor.mapApiKey == 'changlong_draw_no_tail_sp' ? '尾 ' : ''}}{{item}}</td>
            </tr>
            <!-- 雙層 -->
            <tr class="title" v-else>
                <td v-for="(item,index) in ['期數'].concat(Object.keys(dataList[dataInfor.mapApiKey][ballIndex]))" :key="index">{{dataInfor.mapApiKey == 'changlong_draw_no_tail' ? '尾 ' : ''}}{{item}}</td>
            </tr>
            <!-- 大小单双 table -->
            <template  v-if="dataInfor.mapApiKey == 'changlong_two_sides'">
                <!--dataList['大'].length:主要是拿期數 -->
                <tr v-for="(item,index) in dataList['changlong_two_sides'][ballIndex]['单'].length" :key="index" class="result_list">
                    <td>{{index+2}} 期</td>
                    <td>{{dataList['changlong_two_sides'][ballIndex]["大"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_two_sides'][ballIndex]["小"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_two_sides'][ballIndex]["单"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_two_sides'][ballIndex]["双"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_two_sides'][ballIndex]["尾大"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_two_sides'][ballIndex]["尾小"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_two_sides'][ballIndex]["合单"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_two_sides'][ballIndex]["合双"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_two_sides'][ballIndex]["合大"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_two_sides'][ballIndex]["合小"][index] | formatLongReminderValue}}</td>
                </tr> 
            </template>

            <!-- 大小单双 table 特碼-->
            <template  v-if="dataInfor.mapApiKey == 'changlong_two_sides_sp'">
                <!--dataList['大'].length:主要是拿期數 -->
                <tr v-for="(item,index) in dataList['changlong_two_sides_sp']['单'].length" :key="index" class="result_list">
                    <td>{{index+2}} 期</td>
                    <td>{{dataList['changlong_two_sides_sp']["大"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_two_sides_sp']["小"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_two_sides_sp']["单"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_two_sides_sp']["双"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_two_sides_sp']["尾大"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_two_sides_sp']["尾小"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_two_sides_sp']["合单"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_two_sides_sp']["合双"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_two_sides_sp']["合大"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_two_sides_sp']["合小"][index] | formatLongReminderValue}}</td>
                </tr> 
            </template>

            <!-- 生肖 table -->
            <template  v-if="dataInfor.mapApiKey == 'changlong_draw_no_zodiac'">
                <tr v-for="(item,index) in dataList['changlong_draw_no_zodiac'][ballIndex]['鼠'].length" :key="index" class="result_list">
                    <td>{{index+2}} 期</td>
                    <td>{{dataList['changlong_draw_no_zodiac'][ballIndex]["鼠"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_draw_no_zodiac'][ballIndex]["牛"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_draw_no_zodiac'][ballIndex]["虎"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_draw_no_zodiac'][ballIndex]["兔"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_draw_no_zodiac'][ballIndex]["龙"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_draw_no_zodiac'][ballIndex]["蛇"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_draw_no_zodiac'][ballIndex]["马"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_draw_no_zodiac'][ballIndex]["羊"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_draw_no_zodiac'][ballIndex]["猴"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_draw_no_zodiac'][ballIndex]["鸡"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_draw_no_zodiac'][ballIndex]["狗"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_draw_no_zodiac'][ballIndex]["猪"][index] | formatLongReminderValue}}</td>
                </tr> 
            </template>

            <!-- 生肖 table 特碼-->
            <template  v-if="dataInfor.mapApiKey == 'changlong_draw_no_zodiac_sp'">
                <tr v-for="(item,index) in dataList['changlong_draw_no_zodiac_sp']['鼠'].length" :key="index" class="result_list">
                    <td>{{index+2}} 期</td>
                    <td>{{dataList['changlong_draw_no_zodiac_sp']["鼠"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_draw_no_zodiac_sp']["牛"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_draw_no_zodiac_sp']["虎"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_draw_no_zodiac_sp']["兔"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_draw_no_zodiac_sp']["龙"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_draw_no_zodiac_sp']["蛇"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_draw_no_zodiac_sp']["马"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_draw_no_zodiac_sp']["羊"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_draw_no_zodiac_sp']["猴"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_draw_no_zodiac_sp']["鸡"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_draw_no_zodiac_sp']["狗"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_draw_no_zodiac_sp']["猪"][index] | formatLongReminderValue}}</td>
                </tr> 
            </template>

            <!-- 尾數 table -->
            <template  v-if="dataInfor.mapApiKey == 'changlong_draw_no_tail'">
                <tr v-for="(item,index) in dataList['changlong_draw_no_tail'][ballIndex][0].length" :key="index" class="result_list">
                    <td>{{index +2}} 期</td>
                    <td>{{dataList['changlong_draw_no_tail'][ballIndex][0][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_draw_no_tail'][ballIndex][1][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_draw_no_tail'][ballIndex][2][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_draw_no_tail'][ballIndex][3][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_draw_no_tail'][ballIndex][4][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_draw_no_tail'][ballIndex][5][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_draw_no_tail'][ballIndex][6][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_draw_no_tail'][ballIndex][7][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_draw_no_tail'][ballIndex][8][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_draw_no_tail'][ballIndex][9][index] | formatLongReminderValue}}</td>
                </tr> 
            </template>

            <!-- 尾數 table 特碼-->
            <template  v-if="dataInfor.mapApiKey == 'changlong_draw_no_tail_sp'">
                <tr v-for="(item,index) in dataList['changlong_draw_no_tail_sp'][0].length" :key="index" class="result_list">
                    <td>{{index +2}} 期</td>
                    <td>{{dataList['changlong_draw_no_tail_sp'][0][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_draw_no_tail_sp'][1][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_draw_no_tail_sp'][2][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_draw_no_tail_sp'][3][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_draw_no_tail_sp'][4][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_draw_no_tail_sp'][5][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_draw_no_tail_sp'][6][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_draw_no_tail_sp'][7][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_draw_no_tail_sp'][8][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_draw_no_tail_sp'][9][index] | formatLongReminderValue}}</td>
                </tr> 
            </template>

            <!-- 色波 table -->
            <template  v-if="dataInfor.mapApiKey == 'changlong_draw_no_color'">
                <tr v-for="(item,index) in dataList['changlong_draw_no_color'][ballIndex]['红'].length" :key="index" class="result_list">
                    <td>{{index +2}} 期</td>
                    <td>{{dataList['changlong_draw_no_color'][ballIndex]["红"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_draw_no_color'][ballIndex]["绿"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_draw_no_color'][ballIndex]["蓝"][index] | formatLongReminderValue}}</td>
                </tr> 
            </template>

            <!-- 色波 table 特碼-->
            <template  v-if="dataInfor.mapApiKey == 'changlong_special_draw_no_color'">
                <tr v-for="(item,index) in dataList['changlong_special_draw_no_color']['红波'].length" :key="index" class="result_list">
                    <td>{{index +2}} 期</td>
                    <td>{{dataList['changlong_special_draw_no_color']["红波"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_special_draw_no_color']["绿波"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_special_draw_no_color']["蓝波"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_special_draw_no_color']["半波红大"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_special_draw_no_color']["半波红小"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_special_draw_no_color']["半波绿大"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_special_draw_no_color']["半波绿小"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_special_draw_no_color']["半波蓝大"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_special_draw_no_color']["半波蓝小"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_special_draw_no_color']["半波红单"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_special_draw_no_color']["半波红双"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_special_draw_no_color']["半波绿单"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_special_draw_no_color']["半波绿双"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_special_draw_no_color']["半波蓝单"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_special_draw_no_color']["半波蓝双"][index] | formatLongReminderValue}}</td>
                </tr> 
            </template>

            <!-- 总和长龙 table -->
            <template  v-if="dataInfor.mapApiKey == 'changlong_sum'">
                <tr v-for="(item,index) in dataList['changlong_sum']['大'].length" :key="index" class="result_list">
                    <td>{{index +2}} 期</td>
                    <td>{{dataList['changlong_sum']["大"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_sum']["小"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_sum']["单"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_sum']["双"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_sum']["七色波(红)"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_sum']["七色波(绿)"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_sum']["七色波(蓝)"][index] | formatLongReminderValue}}</td>
                </tr> 
            </template>
        </table> 
    </div>  
</template>
<script>
import {longReminderTableConfig} from "@/assets/longReminderTableConfig.js"
import { getLotteryLongReminder } from '@/api/api';
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
    props:{
        dataInfor:{
            type: Object,
        },
        lotteryCode: {
            type: String,
            default: "xglhc"
        },
        dataLength: {
            type: String,
            default: "100"
        },
        ballIndex: {
            type: Number,
            default: 0
        },
    },
    computed: {
        ...mapState([
            "baseInfo",
            "lotteryDrawTime"
        ]),
    },
    watch: {
        dataLength: function (val) {
            this.dataFormat();
        },
    },
    data(){
        return{
            dataList: null,
            timer: null,
            apiResult: null,
        }
    },
    methods:{
        async getInit(){
            try{
                this.apiResult = await getLotteryLongReminder(this.lotteryCode);
                this.dataFormat();
            }catch(e){
                
            }
        },
        dataFormat() {
            try{
                let result = this.apiResult;
                let display_data = result[this.dataLength];
                let data_keys = Object.keys(display_data);
                let sp_data = {};
                data_keys.forEach((key)=> {
                    if(Array.isArray(display_data[key])) {
                        sp_data[key + '_sp'] = display_data[key].pop();
                    }
                })
                this.dataList = Object.assign(display_data, sp_data);
            }catch(e){
                
            }
        }
    },
    async mounted(){
        this.getInit();
        this.timer = setInterval(()=>{
            if(this.lotteryDrawTime[this.lotteryCode] <= 0) {
                this.getInit();                
            }
        },1000);
    },
   destroyed() {
        clearInterval(this.timer);
    }
   
}

</script>
<style lang="scss" scoped>
@import '@/style/_longReminder.scss';
</style>

