<template>
    <div>
        <!-- <GameDetailSubBtn :currentTab="currentTab"/> -->
        <!----內部子選單 --->
        <div class="result_top">
            <!--- 子選單 --->
            <div class="lottoy_subMenu">
                <div 
                    v-for="(item,index) in subMenu" :key="index"
                    class="subMenu_btn"
                    :class="{'active':currentsubMenuIndex == index}" 
                    @click="changeSubMenu(index)">
                    {{item.name}}
                </div>
            </div>
            <div class="right">
                <span class="statistics_period">统计期数</span> <span class="period">100 期</span>
            </div>
        </div>   
        <!--- 顯示結果 --->
        <!--- 六合彩 ---> 
        <Lhc :dataInfor="dataInfor" v-if="$route.params.name==='twlhc' || $route.params.name == 'xglhc'"/>
        <!--- 七星彩 --->
        <Qxc :dataInfor="dataInfor" v-if="$route.params.name==='qxc'" /> 
        <!--- 美國強力球 --->  
        <UsPowerball :dataInfor="dataInfor" v-if="$route.params.name == 'uspowerball'"/>
        <!--- 美國超級百萬 ---> 
        <UsSuperMillions :dataInfor="dataInfor" v-if="$route.params.name == 'usmegamillions'"/>
        <!--- 欧洲百万 ---> 
        <EurMillions  :dataInfor="dataInfor" v-if="$route.params.name == 'euromillions'"/>
        <!--- 澳大利亚Saturday ---> 
        <AuSaturday :dataInfor="dataInfor" v-if="$route.params.name == 'ausaturdaylotto'"/>
      
       
    </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { getLotteryRoadbead } from '@/api/api';
import Lhc from "@/components/lottory_result/pearl/Lhc"
import Qxc from "@/components/lottory_result/pearl/Qxc"
import UsPowerball from "@/components/lottory_result/pearl/UsPowerball";
import UsSuperMillions from "@/components/lottory_result/pearl/UsSuperMillions";
import EurMillions from "@/components/lottory_result/pearl/EurMillions";
import AuSaturday from "@/components/lottory_result/pearl/AuSaturday";
export default {
    components:{
        Lhc,
        Qxc,
        UsPowerball,
        UsSuperMillions,
        EurMillions,
        AuSaturday
    },
    props:{
        currentTab:{
            type: String
        }
    },
    watch: {
        '$route': {
            handler: function(to, from) {
                this.query();
                this.currentsubMenuIndex =0;
            },
            //immediate: true
        } 
    },
    data(){
        return{
            allData:[],
            period:100,
            subMenu:[],
            currentsubMenuIndex:0,
            dataInfor:{
                dataList:[],
                dataHeader:[],
                dataTitle:'',
                mapApiKey:'',
                flateData:[],
            },
            timer: null,
        }
    },
    computed: {
        ...mapState([
            "baseInfo",
            "lotteryDrawTime"
        ]),
    },
    methods: {
        // 查詢所有資料
        async query(){ 
            const result = await getLotteryRoadbead(this.$route.params.name);
            this.allData = result[this.period];
            this.getSubMenu(); 
        }, 
        // 獲取全部子選單
        getSubMenu(){
            this.subMenu = this.$lotteryConfig[this.$route.params.name].mainMenu[this.currentTab].subMenu;
            this.outputData();
        },   
        // 獲取目前應對應資料
        outputData(){ 
            this.dataInfor.mapApiKey = this.subMenu[this.currentsubMenuIndex].key;
            this.dataInfor.dataList = this.allData[this.dataInfor.mapApiKey];
            //this.analyzeData(this.dataInfor.dataList);

            let tempDataList = [];
            // 扁平化資料
            this.dataInfor.dataList.forEach((item, index, array)=>{
                tempDataList[index] = [].concat(...this.dataInfor.dataList[index]);
            });
            this.dataInfor.flateData = tempDataList;
        },
        // 切換子選單
        changeSubMenu(index){
            this.currentsubMenuIndex = index;
            this.outputData(); 
        },
    },
    mounted(){
        this.query(); 
        this.timer = setInterval(()=>{
            if(this.lotteryDrawTime[this.$route.params.name] <= 0) {
                this.query(); 
            }
        },1000)
    },
    destroyed() {
        clearInterval(this.timer);
    }
   
}
</script>
<style lang="scss" scoped>
.result_top{
    align-items: center;
    display: flex;
    justify-content: space-between;
    .right{
        font-size:14px ;
        .statistics_period{
           color: #646363;
        }
        .period{
            margin-left: 32px;
            color: #0f2957;
        }
    }
}

// 子選單
.lottoy_subMenu{
    box-sizing: border-box;
    display: flex;
    .subMenu_btn{
        cursor: pointer;
        display: inline-block;
        color: #2e2e2e;
        font-size: 14px;
        width: 90px;
        height: 32px;
        border: 1px solid #2e2e2e;
        line-height: 32px;
        text-align: center;
        border-radius: 50px;
        margin-right: 16px;
        &:hover{
            color: #ffe6cc;
            background: #001236;
        }
        &.active{
            color: #ffe6cc;
            background: #001236;
        }
    }
}

// level1 選單
.level1Menu_wrapper{
    width: fit-content;
    &.useBorderBotton{
        border-bottom: 1px solid #ccc;
    }
    .level1Menu_btn{
        padding: 17px 0;
        cursor: pointer;
        display: inline-block;
        color: #5a99c6;
        font-size: 14px;
        width: 92px;
        line-height: 32px;
        text-align: center;
        margin-right: 16px;
        &:hover{
            color: #c65a5a;
            background: #fff;
        }
        &.active{
            color: #001236; 
        }
    }
}

// level2 選單
.level2Menu_wrapper{
    width: fit-content;
    .level2Menu_btn{
        padding: 17px 0;
        cursor: pointer;
        display: inline-block;
        color: #aab2ba;
        font-size: 14px;
        width: 92px;
        line-height: 32px;
        text-align: center;
        margin-right: 16px;
        &:hover{
            color: #c65a5a;
            background: #fff;
        }
        &.active{
            color: #001236; 
        }
    }
}

.table-header{
    width: 100%;
    height: 0.7rem;
    background-color: #c3d3e3;
    font-size: 0.17rem;
    display: flex;
    line-height: .7rem;
    padding: 0 .32rem;
    box-sizing: border-box;
    justify-content: flex-start;
    margin-top: 40px;
    .left-text{
        color: #0f2957;
        margin-right: 30px;
    }   
    .right-text{
        color: #0f2957;
    }
}
.wrapper{
    table{  
        td{
            padding: 10px 0 35px 0;
            &:nth-child(odd){
                background: #f0f0f0;
            }
        }
    }
}
</style>
