<template>
    <div> 
        <table>
          <tr class="header-title">
            <td class="time">时间</td>
            <td class="period">期数</td>
            <td class="result">开奖号码</td>
            <td class="sum">佰拾和</td>
            <td class="sum">佰个和</td>
            <td class="sum">拾个和</td>
            <td class="sum">总和</td>
          </tr>
            <!--- 歷史結果列表 --->
          <tr v-for="(item,index) in dataList" :key="index" class="history_item">
              <td v-if="item.draw_time">{{item.draw_time[0]| formateDrawTimeAll}}</td>
              <td>{{item.period_no[0]}}</td>
              <td>
                <div class="ball-wrapper">
                  <div :class="`${lotteryCode} ball ball-${parseInt(num)} solid`"  v-for="(num,index) in item.ShowNumber" :key="index">{{num}}</div>
                </div>
              </td>
              <td class="bzh sum_result" v-if="item.HundredTenSum">
                <span >{{item.HundredTenSum[0]}}</span>
                <span :class="getUseColor(item.HundredTenSum[1])">{{item.HundredTenSum[1]}}</span>
                <span :class="getUseColor(item.HundredTenSum[2])">{{item.HundredTenSum[2]}}</span>
              </td>
              <td class="bgh sum_result" v-if="item.HundredOneSum">
                <span>{{item.HundredOneSum[0]}}</span>
                <span :class="getUseColor(item.HundredOneSum[1])">{{item.HundredOneSum[1]}}</span>
                <span :class="getUseColor(item.HundredOneSum[2])">{{item.HundredOneSum[2]}}</span>
              </td>
              <td class="zgh sum_result" v-if="item.TenOneSum">
                <span>{{item.TenOneSum[0]}}</span>
                <span :class="getUseColor(item.TenOneSum[1])">{{item.TenOneSum[1]}}</span>
                <span :class="getUseColor(item.TenOneSum[2])">{{item.TenOneSum[2]}}</span>
              </td>
              <td class="total sum_result" v-if="item.TotalSum">
                <span>{{item.TotalSum[0]}}</span>
                <span :class="getUseColor(item.TotalSum[2])">{{item.TotalSum[2]}}</span>
                <span :class="getUseColor(item.TotalSum[1])">{{item.TotalSum[1]}}</span>
              </td>
            </tr>

        </table>
    </div>
</template>
<script>
export default {
    props:{
        dataList:Array,
        lotteryCode:String
    }, methods:{
        getUseColor(string){
            if( string === '单' || string === '小' || string === '尾小'){
                return 'color-blue'
            }
            return 'color-red'
        },
    } 
}

</script>
<style lang="scss" scoped>

.header-title{
    background: #203d7f;
    color: #ffe6cc;
    height: 70px;
    td{
        border: 1px solid #a2bdec;
        font-size: 18px;
    }
    .time{
        width: 215px;
    }
    .period{
        width: 215px;
    }
    .drawNum{
        width: 567px;
    }
    .result{
        width: 446px;
    }
}


/** 歷史列表
******************************/
.history_item{
    height: 73px;
    font-size: 14px;
    color: #646363;
    &:nth-child(even) {
        background: #d0dce8
    }
    .ball-wrapper{
        display: flex;
        justify-content: center;
        .ball{
            color: #fff;
            margin-right: 20px;
        }
    }
     .sum__wrapper{
        display: flex;
        .text{
           
            width: 50%;
            &:last-child{
                font-weight: 600;
            }
        }
        
    } 
    td{
        border: 1px solid #a2bdec;
        font-size: 14px;
    }

}
.sum_result{
  span {
    display: table-cell;
    width:1%;
    font-size:14px;
  }
}


</style>
