<template>
    <div class="header_menu" v-if="isShow" @mouseleave="hideMenu">
        <div class="menu_content">
            <div class="menu_option" :style="column == 2 ? 'grid-template-columns: 170px 170px 170px 170px;':'grid-template-columns: 160px;'">
                <div v-for="(item,index) in lotteryList" :key="index" class="menu_item">
                    <template v-if='item.isNew' >
                      <a @click="hideMenu" :href="item.url" :target="RegExp(/http/).test(item.url) ? '_blank': ''">
                        {{item.name}}
                        <img src="@/img/new_img.png" class="icon">
                      </a>
                    </template>
                    <a @click="hideMenu" v-else :href="item.url" :target="RegExp(/http/).test(item.url) ? '_blank': ''">{{item.name}}</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    export default {
        name: 'HeaderMenu',
        props: {
            msg: String,
            lotteryList: {
                type: Array,
                default: []
            },
            column: Number
        },
        data() {
        return {
                isShow: true,
            }
        },
        components: {
        },
        methods: {
            showMenu () {
                this.isShow = true;
            },
            hideMenu () {
                this.$emit("hideMenu");
            },
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .header_menu {
        position: absolute;
        z-index: 100;
        height: auto;
        background-color: rgba(2, 18, 52, 0.9);
        box-shadow: 0 1px 5px 1px #000;
        font-family: Arial, 'Microsoft YaHei', 'PingFang', sans-serif;
        .menu_content {
            position: relative;
            box-sizing: border-box;
            
            .menu_option{     
                padding: 5px 0;
                display: grid;
                .menu_item{ 
                    text-align: center;
                    cursor: pointer;                    
                    height: 40px;
                    a{
                        color: #7fb2eb;
                        font-size: 17px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 40px;
                        letter-spacing: normal;
                    }
                    a:hover{
                        color: #ffe6cc;
                    }
                }
            }
        }
    }
    a{text-align:center;position:relative;padding-top:2px;padding-bottom:2px;}
    .icon {
      position: absolute;
      top: -18px;
      right: -16px;
    }
</style>