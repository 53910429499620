<template>
    <div class="table_infor">
        <div class="title">总和</div>
        <div class="desc" v-if="lastperiod.display_info">
            <div class="item">{{lastperiod.display_info.sum}}</div>
            <div class="line">|</div>
            <div class="item">{{lastperiod.display_info.sum_odds_even}}</div>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        lastperiod:Object
    }
}
</script>
<style lang="scss" scoped>
.table_infor{
    width: 100%;
    font-size: 17px;
    color: #646363;
    .title{
        text-align: center;
        height: 50px;
        border-bottom: 1px solid #ccc;
        line-height: 50px;
    }
    .desc{
        display: flex;
        .item{
            width: 50%;
            line-height: 50px;
            text-align: center;
        }
        .line{
            line-height: 45px;
            color: #aab2ba;
        } 
    }
}
</style>