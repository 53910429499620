<template>
    <div class="game_rule">
        <div class="content">
            <div class="bookmark_row">
                <div class="bookmark disable" @click="changePage(0)">
                    关于我们
                </div>
                <div class="bookmark disable" @click="changePage(1)">
                    免责声明
                </div>
                <div class="bookmark">
                    玩法规则
                </div>
            </div>
            <div class="text_box">
                <div class="option">
                    <div class="lottery" :class="gameRuleIndex==0?'checked':''" @click="changeRule(0)">
                        七星彩
                    </div>
                    
                    <div class="lottery" :class="gameRuleIndex==2?'checked':''" @click="changeRule(2)">
                        香港六合彩
                    </div>
                    <div class="lottery" :class="gameRuleIndex==3?'checked':''" @click="changeRule(3)">
                        排列三
                    </div>
                    <div class="lottery" :class="gameRuleIndex==5?'checked':''" @click="changeRule(5)">
                        排列五
                    </div>
                    
                    <div class="line"></div>
                    
                </div>
                <QXC v-if="gameRuleIndex==0"/>
                <TWlhc v-if="gameRuleIndex==1"/>
                <HKlhc v-if="gameRuleIndex==2"/>
                <PL3 v-if="gameRuleIndex==3"/>
                <PL5 v-if="gameRuleIndex==5"/>    
            </div>
        </div>
        <!--新聞-->
        <NewsCollection />
    </div>
</template>

<script>
    import NewsCollection from '@/components/common/NewsCollection.vue'
    import QXC from '@/components/game_rule/qxc.vue'
    import TWlhc from '@/components/game_rule/twlhc.vue'
    import HKlhc from '@/components/game_rule/hklhc.vue'
    import PL3 from '@/components/game_rule/pl3'
    import PL5 from '@/components/game_rule/pl5'
    export default {
        name: 'GameRule',
        components: {
            NewsCollection,
            QXC,
            TWlhc,
            HKlhc,
            PL3,
            PL5,
        },
        data() {
            return {
                pageUrl: [
                    "aboutus", "disclaimer", "gamerule"
                ],
                gameRuleIndex: 0,
            }
        },
        computed: {
        },
        methods: {
            changePage (i) {
                this.$router.push(this.pageUrl[i]);
            },
            changeRule (i) {
                this.gameRuleIndex = i;
            },
        },
        mounted() {
        }
    }
</script>

<style scoped lang="scss">

</style>