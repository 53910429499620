<template>
    <div class="news_collection">
        <div class="title_frame">
            <div class="title">
                最新消息
            </div>
            <div class="bottom_bar">
            </div>
        </div>
        <div class="content">
            <div v-for="(item, index) in newsList" :key="'news' + index" class="item">
                <News 
                :title="item.title"
                :content="item.content"
                :type="item.type"
                :time="item.time"
                :img="item.img"
                :sourcesIcon="item.sourcesIcon"
                :index="index"
                :url="item.url"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import News from '@/components/common/News.vue'
    import Setting from '@/news/news.json'

    export default {
        name: 'NewsCollection',
        components: {
            News
        },
        props: {
            msg: String
        },
        data() {
            return {
                newsList: [],
            }
        },
        mounted() {
            let setting = Setting;
            for(let i = 0 ; i<6 ; i++) {
                this.newsList.push(setting[i]);
            }
        }
        
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .news_collection {
        padding:70px 15px 100px;
        .content {
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          grid-gap: 15px;
          .item {
            width: 100%;
            height: auto;
            background-size: cover;
          }


          .A {
              background-image: url('~@/img/ad1.png');
          }

          .B {
              background-image: url('~@/img/ad2.png');
          }

          .C {
              background-image: url('~@/img/ad3.png');
          }
          @media(min-width: 768px){
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 20px;
          }
          @media(min-width: 992px){
            grid-template-columns: repeat(3, 1fr);
          }
        }
        @media(min-width:992px){
          padding: 20px 15px 80px;
          background-color: #fff;
          width:100%;
        }
    }
    @media (min-width: 992px) {
      .content{margin-top:0;width:100%;}
    }
    @media(min-width:1440px){
      .content{width:1400px;}
    }
    .title_frame{
      position: relative;
      height: 70px;
      margin-bottom: 60px;
      display:none;
      @media(min-width:992px){
        display:block;
      }
    }
    .title{
      font-size: 32px;
      font-weight: 300;
      text-align: center;
      color: #0f2957;
    }
    .bottom_bar{
      width: 60px;
      height: 2px;
      background-color: #596c81;
      position: absolute;
      bottom: 0;
      left: calc(50% - 30px);
    }
</style>