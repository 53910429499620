<template>
    <div>
      {{currentHistoryName}}
        <!-- :page-link-class="'item'" -->
        <div class="paginate_wrapper"  >
            <paginate
                v-if="error !== true"
                ref="pager"
                :page-count="pageTotal"
                :click-handler="clickPage"
                :prev-link-class="'prevLink'"
                :disabled-class="'disabled'"
                :active-class="'active'"
                :next-link-class="'nextLink'"
                :page-link-class="'link'"
                :page-class="'item'"
                prev-text=""
                next-text=""
                :container-class="'paginateStyle'">
            </paginate>
        </div>
        <div v-if="error === true">暂无数据</div>
        <div v-if="error !== true">
            <!--- 六合彩 ---> 
            <Lhc
                v-if="currentHistoryName === 'xglhc' || currentHistoryName === 'kl8lhc'"
                :dataList="dataList"
                :lotteryCode="currentHistoryName"
                @openDrawingVideo="openDrawingVideo"
            />
            <Lhc v-else-if="currentHistoryName === 'lucky7daily'" :dataList="dataList" :lotteryCode="currentHistoryName"/>
            <!--- 七星彩 --->
            <Qxc v-else-if="currentHistoryName === 'qxc'" :dataList="dataList" :lotteryCode="currentHistoryName"/>

            <Pl3 v-else-if="currentHistoryName === 'pl3'" :dataList="dataList" :lotteryCode="currentHistoryName"/>
            <Pl5 v-else-if="currentHistoryName === 'pl5'" :dataList="dataList" :lotteryCode="currentHistoryName"/>
            <!--- 美國強力球 --->
            <Default v-else :dataList="dataList" :lotteryCode="currentHistoryName"/>

        </div>
        
    </div> 
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { getLotteryHistory, getLotteryHistoryStatistics } from '@/api/api';
import {lotteryNameMappingBackend} from "@/utils/lotteryNameMappingBackend";
import Lhc from "@/components/lottory_result/history/Lhc";
import Qxc from "@/components/lottory_result/history/Qxc";
import Pl3 from "@/components/lottory_result/history/Pl3";
import Pl5 from "@/components/lottory_result/history/Pl5";
import Default from "@/components/lottory_result/history/Default";
export default {
    components:{
        Lhc,
        Pl3,
        Pl5,
        Qxc,
        Default
    },
    data(){
        return{
            currentHistoryName:'',
            dataList: [],
            pageIndex:1, // 當前頁數
            pageSize:20, // 每頁顯示幾筆
            records: 0,  // 總資料筆數
            pageTotal:0,  // 總頁數
            timer: null,
            error: null,
            callHistory: false,
        }
    },
    watch: {
        '$route': {
            handler: function(to, from) {
               this.currentHistoryName = to.params.name;
               this.pageIndex = 1;
               this.dataList = [];
               this.getHistoryInfo();
               this.lotteryUtil.changeLottery();
               let pager = this.$refs.pager;
               if(pager) {
                   pager.innerValue = 1;
               }    
            },
            immediate: true
        } 
    },
    computed: {
        ...mapState([
            "baseInfo",
            "lotteryDrawTime"
        ]),
    },
    methods:{
        async getHistoryInfo(){
            try{
                if(this.currentHistoryName ==='twlhc' || this.currentHistoryName === 'hklhc'){
                    this.pageSize = 15;
                }
                let name = this.currentHistoryName;
              const result = await getLotteryHistoryStatistics(name,this.pageIndex,'');
              this.dataList = result.data.statistics;
              //多餘的
              //this.pageToatl = Math.ceil(result.data.record_count / this.pageSize);

                if (result.status && result.status === 500){
                    this.error = true;
                }else{
                  this.error = false;
                }
                
                this.pageTotal = Math.ceil(result.data.record_count / this.pageSize);
            }catch(e){
                
            }
        },
        clickPage(num){
            this.pageIndex = num;
            this.getHistoryInfo();
        },
      openDrawingVideo(item){
        this.$emit('openDrawingVideo', item);
      }
    },
    mounted(){
        this.currentHistoryName = this.$route.params.name;
        this.getHistoryInfo();
        this.timer = setInterval(()=>{
            if(this.lotteryDrawTime[this.currentHistoryName] <= 0 ) {
                this.callHistory=true;
            }
            if(this.callHistory ) {
                this.callHistory=false;
                this.getHistoryInfo(); 
                 if(this.lotteryDrawTime[this.currentHistoryName] <= 0 ) {
                    this.lotteryUtil.changeLottery();
                }
            }
        },3000);
    },
    destroyed() {
        clearInterval(this.timer);
    }
}
</script>
<style lang="scss" scoped>
.paginate_wrapper{
    margin-bottom: 16px;
    display: flex;
    justify-content: flex-end;
}
</style>
