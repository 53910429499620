import store from '@/store';
import { getBaseInfo, getLotteryInfo } from '@/api/api.js';
import { lotteryNameMappingFrontend } from '@/utils/lotteryNameMappingBackend';
import { lotteryConfig } from '@/assets/lotteryConfig.js';
import router from '@/router';
let updateBaseInfoColdDown = 0;
let updateBaseInfoRange = 20;

let lotteryDrawTime = null;

let lotteryCodeList = Object.keys(lotteryConfig);
let currentLotteryPage = '';

export function getLotteryStatus(lotteryCode) {
  return store.state.baseInfo[lotteryCode];
}

function baseInfoHandle(baseInfo, currentLottery) {
  let newlotteryDrawTime = {};

  if (Object.keys(baseInfo).length > 4) {
    for (let lottery in baseInfo) {
      let lotteryInfo = baseInfo[lottery];
      let lotteryDrawTime = lotteryInfo.current_period?.draw_time;
      let lotteryServerDrawTime = lotteryInfo.server_time;
      newlotteryDrawTime[lottery] = getDrawTiming(
        lotteryDrawTime,
        lotteryServerDrawTime
      );
    }
  } else {
    let lotteryDrawTime = baseInfo.current_period.draw_time;
    let lotteryServerDrawTime = baseInfo.server_time;
    newlotteryDrawTime[currentLottery] = getDrawTiming(
      lotteryDrawTime,
      lotteryServerDrawTime
    );
  }
  lotteryDrawTime = newlotteryDrawTime;
}
function getDrawTiming(drawTimeString, serverDrawTimeString) {
  let drawTime = new Date(checkIOSTime(drawTimeString));
  let nowTime = new Date(checkIOSTime(serverDrawTimeString));
  // //相減後秒
  let difference = parseInt((drawTime.getTime() - nowTime.getTime()) / 1000);
  return difference;
}

function checkIOSTime(time) {
  let userAgent = navigator.userAgent;
  if (userAgent.indexOf('Safari') > -1 && time) {
    time = time.replace(/\-/g, '/');
  }
  return time;
}

function formatInfo(baseInfo) {
  let result = {};
  for (let lottery in baseInfo) {
    let lotteryInfo = baseInfo[lottery];
    result[lotteryNameMappingFrontend(lottery)] = lotteryInfo;
  }
  return result;
}

async function updateBaseInfo(tempStore, currentLottery) {
  if (updateBaseInfoColdDown <= 0) {
    let resBaseInfo = await (currentLottery != null
      ? getLotteryInfo(currentLottery)
      : getBaseInfo());
    const matchInfo = Object.keys(resBaseInfo.data).reduce((object, key) => {
      if (!key.match(/^(sgplhc)/)) {
        object[key] = resBaseInfo.data[key];
      }
      return object;
    }, {});
    tempStore.state.baseInfo = matchInfo;
    baseInfoHandle(tempStore.state.baseInfo, currentLottery);
  }
}

async function updateBaseInfoForLucky7daily(tempStore, currentLottery) {
  if (currentLottery == 'lucky7daily') {
    let resBaseInfo = await (currentLottery != null
      ? getLotteryInfo(currentLottery)
      : getBaseInfo());
    const matchInfo = Object.keys(resBaseInfo.data).reduce((object, key) => {
      if (!key.match(/^(sgplhc)/)) {
        object[key] = resBaseInfo.data[key];
      }
      return object;
    }, {});
    tempStore.state.baseInfo[currentLottery] = matchInfo;

    baseInfoHandle(tempStore.state.baseInfo, currentLottery);
  }
}

function checkCountTime() {
  let config = {
    time: '22:00:02',
    duringTime: '22:05:00',
  };

  let nowTime = new Date().getTime();
  let timePoint = config.time.split(':').map((i) => parseInt(i));
  let duringTimePoint = config.duringTime.split(':').map((i) => parseInt(i));
  let returnValue = false;

  let recent = new Date().setHours(...timePoint);
  let recentDuringTime = new Date().setHours(...duringTimePoint);


  if (nowTime >= recent && nowTime <= recentDuringTime) {
    //現在時間小於間格時間, 大於設定時間
    returnValue = true;
  }
  return returnValue;
}

let lotteryUtil = {
  init: async () => {
    let tempStore = store;
    let currentLottery = null;

    if (router.history.current.params.name != undefined) {
      currentLottery = router.history.current.params.name;
    } else {
      currentLottery = null;
    }

    setInterval(() => {
      if (router.history.current.params.name != undefined) {
        currentLottery = router.history.current.params.name;
      } else {
        currentLottery = null;
      }
      if (!lotteryDrawTime) {
        return;
      }
      lotteryCodeList.forEach((key) => {
        if (!isNaN(lotteryDrawTime[key])) {
          lotteryDrawTime[key] -= 1;
        }
      });
      if (
        lotteryCodeList.find(
          (lotteryCode) =>
            lotteryDrawTime[lotteryCode] <= 0 &&
            !isNaN(lotteryDrawTime[lotteryCode])
        ) &&
        updateBaseInfoColdDown <= 0
      ) {
        updateBaseInfo(tempStore, currentLottery);
        updateBaseInfoColdDown = updateBaseInfoRange;
      }
      tempStore.state.lotteryDrawTime = lotteryDrawTime;

      if (updateBaseInfoColdDown > 0) {
        updateBaseInfoColdDown--;
      }

      if (checkCountTime()) {
        updateBaseInfoForLucky7daily(tempStore, 'lucky7daily');
      }
    }, 1000);
  },
  changeLottery: () => {
    let tempStore = store;
    let currentLottery = null;
    if (router.history.current.params.name != undefined) {
      currentLottery = router.history.current.params.name;
    } else {
      currentLottery = null;
    }
    updateBaseInfoColdDown = 0;

    updateBaseInfo(tempStore, currentLottery);
    updateBaseInfoColdDown = updateBaseInfoRange;
    currentLotteryPage = currentLottery;
  },
  getStatus: (lotteryCode) => {
    return store.state.baseInfo[lotteryCode];
  },
  getDrawTime: (lotteryCode) => {
    return lotteryDrawTime[lotteryCode];
  },
  getComingDraw: () => {
    if (lotteryDrawTime == null) {
      return [];
    }
    let sortable = [];
    let result = [];

    const trimArray = Object.keys(lotteryDrawTime).reduce((object, key) => {
      if (
        !key.match(
          /^(twlhc|alphaforce|falcon|tesseract|rogue|titan|morningmillions|sunshine)/
        ) &&
        lotteryDrawTime[key] > 0
      ) {
        object[key] = lotteryDrawTime[key];
      }

      return object;
    }, {});

    for (let lottery in trimArray) {
      sortable.push([lottery, lotteryDrawTime[lottery]]);
    }
    if (sortable.length == 0) sortable.push([], [], []);
    if (sortable.length == 1) sortable.push([], []);
    if (sortable.length == 2) sortable.push([]);

    let indexOfMax, tempMin;
    for (let t = 0; t < 3; t++) {
      indexOfMax = 0;
      tempMin = sortable[0][1];

      for (let i = 0; i < sortable.length; i++) {
        let lotteryDrawTimeSec = sortable[i][1];
        if (lotteryDrawTimeSec < tempMin) {
          tempMin = lotteryDrawTimeSec;
          indexOfMax = i;
        }
      }
      let item = sortable.splice(indexOfMax, 1)[0];
      result.push(item[0]);
    }

    return result;
  },
};

lotteryUtil.init();

export default lotteryUtil;
