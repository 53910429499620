<template>
    <div>
        <!-- 樂透類型 --->
        <lotteryCardType3 
            v-if="currentLotteryName"
            :lotteryCode="currentLotteryName"
            :lotteryName="lotteryName"
            :imgUrl="imgUrl"
            @openVedio="openVedio"
            @openDrawingVideo="openDrawingVideo"
        />
        <!--- 主選單 --->
        <div class="lottoy_menu_wraper">
            <div class="lottoy_menu">
                <div class="menu_btn"
                    v-for="(item, key) in primaryMenu" 
                    :key="key"
                    :class="{'active':currentTab==key}"
                    @click="changeTab(key)"
                    v-if="item.iconName != ''"
                >
                    <icon :name="item.iconName" :class="{'active':key=='history'}" class="menu_icon"></icon>{{item.name}}  
                </div>
            </div>
        </div> 

        <!--- 顯示結果 --->
        <div class="result">
            <div class="result_view">
                <!-- -currentTab 抓的是 lotteryConfig.js 中的 key 值 --->
                <History v-if="currentTab=='history'" :currentTab="currentTab" @openDrawingVideo="openDrawingVideo" />
                <Pearl v-if="currentTab=='pearl'" :currentTab="currentTab"/>
                <PosTrend v-if="currentTab=='posTrend'" :currentTab="currentTab"/>
                <LongReminder v-if="currentTab=='longReminder'" :currentTab="currentTab" :lotteryCode="currentLotteryName"/>
                <ColdAndHot v-if="currentTab=='coldAndHot'" :currentTab="currentTab"/>
                <StatisticalAnalysis v-if="currentTab=='statisticalAnalysis'" :currentTab="currentTab"/>
            </div>  
        </div>
        <!--視訊-->
        <div v-if="showVedio">
            <pcVideoLive @closeVedio="closeVedio" :lotteryType="currentLotteryName" :lotteryInfo="lotteryInfo"></pcVideoLive>
        </div>

      <div v-if="showDrawingVideo">
        <DrawingVideo @closeDrawingVideo="closeDrawingVideo" :year='year' :month='month' :date='date' :periodNo='periodNo' :drawNumber='drawNumber' :showAnimal='showAnimal' :showFiveElements='showFiveElements' />
      </div>
    </div>
</template>
<script>
import lotteryCardType3 from "@/components/common/lotteryCard/lotteryCard_type3";
import History from "@/components/lottory_result/History_Index";
import Pearl from "@/components/lottory_result/Pearl_Index";
import PosTrend from "@/components/lottory_result/PosTrend_Index";
import LongReminder from "@/components/lottory_result/LongReminder_Index";
import ColdAndHot from "@/components/lottory_result/ColdAndHot_Index";
import StatisticalAnalysis from "@/components/lottory_result/StatisticalAnalysis_Index";
import {lotteryConfig} from "@/assets/lotteryConfig.js";
import pcVideoLive from "@/components/live_stream/pcVideoLive";
import DrawingVideo from "@/components/live_stream/DrawingVideo";

export default {
    name:"Lottery",
    components:{
        History,
        Pearl,
        PosTrend,
        ColdAndHot,
        LongReminder,
        StatisticalAnalysis,
        lotteryCardType3,
        pcVideoLive,
        DrawingVideo
    },
    data(){
        return{
            imgUrl:'',
            currentLotteryName:'',
            primaryMenu:{},
            secondaryMenu:[],
            currentTab:"history",
            showVedio: false,
            showDrawingVideo: false,
            lotteryInfo: '',
          year:'',
          month:'',
          date:'',
          periodNo:'',
          drawNumber: [],
          showAnimal: [],
          showFiveElements: []
        }
    },
    watch: {
        '$route': {
            handler: function(to, from) {
                this.currentTab="history", 
                this.refreshLotteryCard();
            },
            immediate: true
        } 
    },
    methods:{
        async init(){
            if(!lotteryConfig[this.currentLotteryName]){
               alert("無此彩種")
            }
            // 獲取當前彩種圖片url
            this.imgUrl = lotteryConfig[this.currentLotteryName].imgUrl;
            this.lotteryName = lotteryConfig[this.currentLotteryName].name;
            this.getPrimaryMenu()
            // this.getSecondaryMenu(this.currentTab)   
        },

        // 獲取主選單
        getPrimaryMenu(){
            this.primaryMenu = lotteryConfig[this.currentLotteryName].mainMenu;
        },
       
        // 切換主選單
        changeTab(tabName){
            // 只要切換主選單，全部從置
            if(this.currentTab != tabName){
                // this.secondaryMenuOptionIndex = 0;
            }
            this.currentTab = tabName;
        },
        refreshLotteryCard() {
            this.currentLotteryName = this.$route.params.name;
            this.init();
        },
        openVedio(info){
            this.lotteryInfo = info;
            this.showVedio = true;
        },
        closeVedio(){
            this.showVedio = false;
        },
      openDrawingVideo({year, month, date, periodNo, drawNumber, showAnimal, showFiveElements}){
        this.year = year;
        this.month = month;
        this.date = date;
        this.periodNo = periodNo;
        this.drawNumber = drawNumber;
        this.showAnimal = showAnimal;
        this.showFiveElements = showFiveElements;
        this.showDrawingVideo = true;
      },
      closeDrawingVideo(){
        this.showDrawingVideo = false;
      },
    },
    mounted(){
        // 如果有帶 queryString,就改變
        if(this.$route.query.tabName){
            this.currentTab = this.$route.query.tabName;
        }
        this.refreshLotteryCard();
    }
    
}
</script>
<style lang="scss" scoped>


// lottoy_menu
.lottoy_menu_wraper{
    max-width: 1440px;
    margin: 20px auto 0 auto;
    box-sizing: border-box;
    position: relative;
    .lottoy_displayAll{
        display: none;  
    }
    .menu_icon{
        width: 24px;
        height: 24px;
        color: red;
    }
    .lottoy_menu{
        display: flex;
         .menu_btn{
            position:absolute;
            width: 140px;
            height: 42px;
            cursor: pointer;
            font-size: 20px;
            display: inline-block;
            color: #ffdfc7;
            border-right:1px solid #2abdec;
            text-align:center;
            line-height: 45px;
            background: -webkit-linear-gradient(180deg, rgb(30, 59, 125), rgb(8, 34, 93));
            background: linear-gradient(180deg, rgb(30, 59, 125), rgb(8, 34, 93));

            border-radius: 10px 10px 0 0;
            .menu_icon{
                color: #f2cba6;
                vertical-align: text-bottom;
                margin-right: 5px;
                &.active{
                    color: #ffdfc7;
                }
            }
        }
        .active{
            color: #ffdfc7;
           
        }
    }   
}


.result{
    margin: 0 auto 20px auto;
    width: 1440px;
    box-sizing: border-box;
    
}


</style>
