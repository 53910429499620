<template>
    <a class="news" :href="url" target="_blank">
        <div class="img">
            <img :src="showImg" />
            <div class="title">
                <div class="top">
                    <div class="left">
                        <div class="logo">
                            <img :src="showIcon" />                            
                        </div>
                        <div class="time">{{time | formatNewsTime}}</div>
                    </div>
                    <div class="type">{{type}}</div>
                </div>
                <div class="text">
                    {{title}}
                </div>
            </div>
        </div>
        <div class="desc">
            <p>{{content}}</p>
        </div>
    </a>
</template>

<script>
    import img1 from '@/news/01/newsImg.png';
    import img2 from '@/news/02/newsImg.png';
    import img3 from '@/news/03/newsImg.png';
    import img4 from '@/news/04/newsImg.png';
    import img5 from '@/news/05/newsImg.png';
    import img6 from '@/news/06/newsImg.png';

    import icon1 from '@/news/01/newsIcon.png';
    import icon2 from '@/news/02/newsIcon.png';
    import icon3 from '@/news/03/newsIcon.png';
    import icon4 from '@/news/04/newsIcon.png';
    import icon5 from '@/news/05/newsIcon.png';
    import icon6 from '@/news/06/newsIcon.png';

    export default {
        name: 'Header',
        props: {
            msg: String,
            title: {
                type: String,
                default: "",
            },
            content: {
                type: String,
                default: "",
            },
            type: {
                type: String,
                default: "全球",
            },
            img: {
                type: String,
                default: "@/img/test/newsImg.png",
            },
            index: {
                type: Number,
                default: 0,
            },
            time: {
                type: String,
                default: "2021-01-01 00:00:00",
            },
            url: {
                type: String,
                default: "javascript:void(0)",
            },
        },
        data() {
            return {
                showImg: "",
                showIcon: "",
            }
        },
        mounted() {
            switch(this.index) {
                case 0:
                    this.showImg = img1;
                    this.showIcon = icon1;
                    break;
                case 1:
                    this.showImg = img2;
                    this.showIcon = icon2;
                    break;
                case 2:
                    this.showImg = img3;
                    this.showIcon = icon3;
                    break;
                case 3:
                    this.showImg = img4;
                    this.showIcon = icon4;
                    break;
                case 4:
                    this.showImg = img5;
                    this.showIcon = icon5;
                    break;
                case 5:
                    this.showImg = img6;
                    this.showIcon = icon6;
                    break;
                default:
                    this.showImg = img1;
                    this.showIcon = icon1;
            };
        }
    }
</script>

<style scoped lang="scss">
    .news {
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to bottom, #0e2e6a, #183b80 9%, #1e4080 16%, #112a5d 46%, #061939 84%, #00194a);
        cursor: pointer;
        .img {
            height: 254px;
            width: 100%;
            position: relative;

            img {
                position: absolute;
                height: 254px;
                width: 100%;
            }

            .title {
                position: absolute;
                width: 100%;
                height: 72px;
                background-color: rgba(10, 24, 49, 0.8);
                bottom: 0;
                padding: 8px 16px;
                box-sizing: border-box;
                
                .top {
                    height: 28px;
                    width: 100%;
                    position: relative;
                    .left{
                        display: flex;
                        .logo {
                            height: 28px;
                            width: 85px;
                            margin-right: 10px;
                            img{
                                height: 27px;
                                width: 85px;
                            }
                        }
                        .time{
                            font-size: 10px;
                            font-weight: 100;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 2.6;
                            letter-spacing: normal;
                            text-align: left;
                            color: #9099a2;
                        }
                    }
                    

                    .type {
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 36px;
                        height: 18px;
                        border-radius: 5px;
                        background-color: #fba840;
                        
                        font-size: 12px;
                        font-weight: 400;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        text-align: left;
                        color: #0f2957;
                        text-align: center;
                    }
                }

                .text {                    
                    font-size: 18px;
                    font-weight: 100;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 27px;
                    letter-spacing: normal;
                    text-align: left;
                    color: #eaeaea;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }
            }
        }

        .desc {
            min-height: 90px;
            width: 100%;
            padding: 7px 16px;
            box-sizing: border-box;
            background-color: #0f2957;
            
            line-height: 25px;
            font-size: 16px;
            font-weight: 100;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: left;
            color: #7f8993;
            position: relative;
            p{
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }
        }
    }
</style>