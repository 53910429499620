<template>
    <div>
        <div class="top_notice">
            <div class="content">
                <div class="notice">
                    <img style="margin-top: 3px;" src="@/img/notify_icon.svg">              
                    <marquee class="info" direction="left"><span>最新公告： 尊敬的用户，百万富翁资讯网拥有世界各地的彩票！</span></marquee>
                </div>
                <div class="subscription">
                    <div class="btn" @click="setHome">
                        设为首页
                    </div>
                    <div class="btn" @click="addFavorite">
                        加入收藏
                    </div>
                </div>
            </div>
        </div>
        <div class="top_bar">
            <div>
                <img class="top_bar_logo" src="@/img/logo.png" />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TopBar',
        props: {
            msg: String
        },
        data() {
            return {
            }
        },
        components: {
        },
        methods: {
            addFavorite () {
                var t = window.location,
                    e = document.title,
                    i = navigator.userAgent.toLowerCase();
                if(i.indexOf("msie 8") > -1) window.external.AddToFavoritesBar(t, e);
                else if(document.all) try {
                    window.external.addFavorite(t, e)
                } catch(t) {
                    alert("您的浏览器不支持,请手动收藏!")
                } else window.sidebar ? window.sidebar.addPanel(e, t, "") : alert("您的浏览器不支持,请手动收藏!");
            },
            setHome(t) {
                if(document.all) {
                    document.body.style.behavior = "url(#default#homepage)";
                    document.body.setHomePage(t);
                } else {
                    alert("您好,您的浏览器不支持自动设置页面为首页功能,请您手动在浏览器里设置该页面为首页!");
                }
            }
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .top_notice {
        width: 100%;
        height: 30px;
        background-color: #001236;
        border: unset;

        @media (max-width: $mobile_width) {
            display: none;
        }

        .content {
            @include content();
            width: 1200px;
            display: flex;
            justify-content: space-between;

            .notice {
                display: flex;

                .info {
                    width: 50vw;
                    margin-left: 10px;        
                    font-size: 12px;
                    line-height: 30px;
                    font-weight: 300;
                    text-align: left;
                    color: #ffe6cc;
                }
            }

            .subscription {
                display: flex;

                .btn {
                    margin-left: 10px;
                    
                    font-size: 12px;
                    line-height: 30px;
                    font-weight: 300;
                    text-align: left;
                    color: #ffe6cc;
                    cursor: pointer;
                }
            }
        }
    }

    .top_bar {
        width: 100%;
        height: 100px;
        display: flex;
        align-items: center;
        display: -webkit-flex;
        -webkit-align-items: center;
        justify-content: center;
        background-image: linear-gradient(to bottom, #bf9161, #e2ba91 8%, #edc399 18%, #d0a87c 48%, #8e6230 85%, #694323);

        @media (max-width: $mobile_width) {
            display: none;
        }
    }

    .top_bar_logo {
        width: 288px;
        height: 60px;

        @media (max-width: $mobile_width) {
            display: none;
        }
    }
</style>