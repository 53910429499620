<template>
    <div class="lottery_next_time">
        <!-- <div class="live_player">
            <div class="live_img"><img src="@/img/live_big_icon.png"></div>
            <div class="title">开奖直播</div>
        </div> -->
        <div>
            <div class="text">下期开奖</div>
            <div class="countdown" v-if="lotteryDrawTime[lotteryCode] > 0">
                <div class="time" v-if="isLowOpen == true">
                    <p>{{lotteryDrawTime[lotteryCode] | reciprocalDay}}</p>
                    <p class="word">天</p>
                </div>
                <div class="time"  v-if="isLowOpen == true">
                    <p>{{lotteryDrawTime[lotteryCode] | reciprocalHour}}</p>
                    <p class="word">時</p>
                </div>
                <div class="time">
                    <p>{{lotteryDrawTime[lotteryCode] | reciprocalMinute}}</p>
                    <p class="word">分</p>
                </div>
                <div class="time">
                    <p>{{lotteryDrawTime[lotteryCode] | reciprocalSecond}}</p>
                    <p class="word">秒</p>
                </div>
            </div>
            <div class="countdown waitTime" v-else>
                <div class="time">
                   <div class="text"><img class="rotating" src="@/img/H5/return-1.png"><span>开奖中...</span></div>
                </div>
            </div>
        </div> 
    </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import {lotteryConfig} from "@/assets/lotteryConfig";

export default {
    data(){
      return{
        lotteryConfig: lotteryConfig,
      }
    },
    props:{
        lotteryCode:'',
    },
    components:{
    
    },
    computed: {
        ...mapState([
            "baseInfo",
            "lotteryDrawTime"
        ]),
      isForeign: function(){
          return this.lotteryConfig[this.lotteryCode].displayperiodType == 'foreign'
      },
      isLowOpen: function(){
          return this.lotteryConfig[this.lotteryCode].lotteryType == 1
      }
    },
}
</script>
<style lang="scss" scoped>
.lottery_next_time{
    display: flex;
    align-items: center;
    .live_player{
        margin-top: 80px;
        margin-right: 40px;
        .live_img{
            width: 100px;
            height: 36px;
            margin: 0 auto;
            img{
                width: 100%;
            }
        }
        .title{
            text-align: center;
            font-size: 14px;
            color: #aab2ba;
            letter-spacing: 3px;
            margin: 3px 0 32px 0;
        }
    }
    .text{
        text-align: center;
        font-size: 18px;
        color: #0f2957;
        margin-bottom: 16px;
    }
    .countdown{
        display: flex;
        .time{
            padding-top: 20px;
            border-radius: 10px;
            border: 1px solid #aab2ba;
            width: 70px;
            height: 100px;
            margin-left: 5px;
            font-size:30px;
            color: #0f2957;
            text-align: center;
            box-sizing: border-box;
            .word{
                font-size:14px;
                color: #646363;
                margin-top: 5px;
            }
        }
        &.waitTime{
            padding-top:0;
             width: auto;
             .time{
                width: auto;
                min-width: 130px;
                height: auto;
                padding: 10px 20px;
                display: flex;
                justify-content: space-around;
                align-items: center;

                  img{
                      width: 20px;
                  }
                  .text{
                    color: #ff0101;
                    margin-bottom: 0;
                    display: flex;
                    gap:10px;
                }
             }
             span{
                color:#001c5d;
                font-size: 20px;
                font-weight: 500;
            }
        }
    }
}

</style>
