import axios from "axios";

/****** 创建axios实例 ******/
const service = axios.create({
    timeout: 15000  // 请求超时时间
});

/****** request 攔截器******/
service.interceptors.request.use(config => {    
    // app.$vux.loading.show({text: '数据加载中……'});  
    // config.headers['Content-Type'] = 'application/x-www-form-urlencoded';  
    return config;

    }, error => {  //请求错误处理   
        // app.$vux.toast.show({        
        //     type: 'warn',        
        //     text: error   
        // });    
        Promise.reject(error)
    }
);



/****** response攔截器 ******/
service.interceptors.response.use(response => { 
    
       //成功请求到数据        
       
        return response.data;
    },    
    error => {  
        //响应错误处理console.log('error');        
        // console.log(error);        
        // console.log(JSON.stringify(error));         
        // let text = JSON.parse(JSON.stringify(error)).response.status === 404            
        //     ? '404'            
        //     : '网络异常，请重试';        
        // app.$vux.toast.show({            
        //     type: 'warn',            
        //     text: text        
        // });         
        return Promise.reject(error)   
    }
)

export default service;