import ajax from './request'

// 獲取全部彩種當前歷史資訊
export function getBaseInfo(){
    return ajax({
        url: '/api/lottery/baseInfo',
        method: 'get',  
    })
}


export function getLotteryInfo(lottery){
    return ajax({
        url: `/api/lottery/baseInfo/${lottery}`,
        method: 'get',
    })
}
// 獲取該彩種歷史資訊
export function getLotteryHistory(name,pageIndex,pageSize){
    return ajax({
        url: `/api/history/${name}?pageIndex=${pageIndex}&pageSize=${pageSize}`,
        method: 'get', 
    })
}

// 獲取該彩種歷史資訊 for date
export function getLotteryHistoryForDate(name,date){
    return ajax({
        url: `/api/H5/history/${name}?date=${date}`,
        method: 'get', 
    })
}

// 獲取該彩種路珠
export function getLotteryRoadbead(name){
    return ajax({
        url: `/api/changlong/roadbead/${name}`,
        method: 'get', 
    })
}

// 长龙提醒
export function getLotteryLongReminder(gameId){
    return ajax({
        url: `/api/changlong/notice/${gameId}`,
        method: 'get',
    })
}// 冷熱分析
export function getLotteryColdHot(name){
    return ajax({
        url: `/api/changlong/coldhot/${name}`,
        method: 'get', 
    })
}

// 統計分析
export function getLotteryStatistics(name){
    return ajax({
        url: `/api/changlong/statistics/${name}`,
        method: 'get', 
    })
}

// 獲取該彩種歷史統計資訊 for page&date
export function getLotteryHistoryStatistics(name,pageIndex,date){
    return ajax({
        url: `/api/statistics/${name}?pageIndex=${pageIndex}&date=${date}`,
        method: 'get',
    })
}

// 獲取該彩種歷史統計資訊 for page&date for daily彩種
export function getLotteryHistoryStatisticsDaily(name,pageIndex,date,pageSize){
    return ajax({
        url: `/api/statistics/${name}?pageIndex=${pageIndex}&pageSize=${pageSize}&year=${date}`,
        method: 'get',
    })
}

// 英國六 - 圖紙API
export function getPredictPublication(LotteryCode){
    return ajax({
        url: `/api/Tuku/GetPredictPublication?lotteryCode=${LotteryCode}`,
        method: 'get',
    })
}
// 英國六 - 圖紙API - 期數
export function getPredictPublicationPeriod(publicationId, pageIndex, pageSize){
    return ajax({
        url: `/api/Tuku/getPredictPublicationPeriod?publicationId=${publicationId}&pageIndex=${pageIndex}&pageSize=${pageSize}`,
        method: 'get',
    })
}
// 英國六 - 圖紙API - 圖片
export function getPeriodPredictSummary(publicationId, periodNo){
    return ajax({
        url: `/api/Tuku/GetPeriodPredictSummary?publicationId=${publicationId}&periodNo=${periodNo}`,
        method: 'get',
    })
}

// 香港六 - 获取六合彩快报类型所有
export function getLhcKBList(){
    return ajax({
        url: `/api/Tuku/GetLhcKBList`,
        method: 'get',
    })
}

// 香港六 - 根据类型获取六合彩快报所有
export function getLhcKBImgALL(kbType){
    return ajax({
        url: `/api/Tuku/GetLhcKBImgALL?kbType=${kbType}`,
        method: 'get',
    })
}
// 香港六 - 根据类型获取六合彩快报所有
export function getGetLhcKBImg(kbTypeId, periodNo){
    return ajax({
        url: `/api/Tuku/GetGetLhcKBImg?kbTypeId=${kbTypeId}&periodNo=${periodNo}`,
        method: 'get',
    })
}

// 取得視訊直播源
export function getLiveStreamSource(lotteryType){
    return ajax({
        url: `/api/Stream/GetStreamUrl?code=${lotteryType}`,
        method: 'get',
    })
}

// client 進入頁面
export function recordUserPageEnter(SiteCode, VisitPath, PageTitle, EnterTime){
    return ajax({
        url: `/stat/api/VisitStat/Enter?SiteCode=${SiteCode}&VisitPath=${VisitPath}&PageTitle=${PageTitle}&EnterTime=${EnterTime}&IpAddr=`,
        method: 'get',
    })
}
// client 離開頁面
export function recordUserPageLeave(SiteCode, OutVisitPath, OutPageTitle, EnterTime, OutTime, PVID){
    return ajax({
        url: `/stat/api/VisitStat/out?SiteCode=${SiteCode}&VisitPath=${OutVisitPath}&PageTitle=${OutPageTitle}&EnterTime=${EnterTime}&OutTime=${OutTime}&PVID=${PVID}&IpAddr=`,
        method: 'get',
    })
}