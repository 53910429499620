<template>
    <div class="top_banner banner">
        <div class="content">
            <div class="top_left_option">
                <div class="row">
                   
                </div>
                <div class="row">
                   
                </div>
                <div class="row">
                  
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: 'Banner',
        props: {
            msg: String
        },
        methods: {
            goto: function name(url) {
                this.$router.push(url);
            },
        },
    }
</script>

<style scoped lang="scss">
    .banner {
        background-image: url('~@/img/bn.jpg'), url('~@/img/pc_banner_gradient.jpg');
        background-repeat: no-repeat, repeat-x;
        background-size: cover;
        background-position: center top;
      cursor: pointer;
    }

    .top_banner {
        width: 100%;
        min-width: 1440px;
        height: 330px;
        z-index: -1;

    }
</style>