<template>
  <div class="top">
    <div class="header">
      <span v-if="!navOpen && $route.name == 'Home'">
        <img class="logo" src="@/img/H5/logo.png">
      </span>
      <span class="title" v-if="!navOpen && $route.path == '/mobile/news'">
        最新消息
      </span>
      <span class="title" v-if="!navOpen && $route.name == 'Lottery'">
        开奖历史
      </span>
      <span class="title" v-if="!navOpen && $route.path == '/mobile/gamerule'">
        游戏规则
      </span>
      <span class="title" v-if="!navOpen && $route.path == '/mobile/site'">
        彩票官网
      </span>
      <span class="title" v-if="!navOpen && $route.path == '/mobile/about'">
        关于我们
      </span>
      <span class="title" v-if="navOpen && $route.path != '/mobile/about'">所有彩种</span>
      <span class="title" v-if="navOpen && $route.path == '/mobile/about'">我的</span>
    </div>
    <div class="sidemenu">
      <div class="sidemenu__btn" v-on:click="navOpen=!navOpen;  $emit('navChange',navOpen);" v-bind:class="{active:navOpen}">
        <span class="top"></span>
        <span class="mid"></span>
        <span class="bottom"></span>
      </div>
    </div>
    <transition>
      <HeaderMenu v-show="navOpen"/>
    </transition>
  </div>
</template>
<script>
import HeaderMenu from '@/components/H5/HeaderMenu.vue'

export default {
  name: 'Top',
  components: {
    HeaderMenu,
  },
  data() {
    return {
      navOpen: false,
      checkShow: true,
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    }
  }
}
</script>
<style lang="scss" scoped>
.top {
  width: 100%;
  z-index: 3;
  position:fixed;
  top: 0;
  .header {
    height: 50px;
    width: 100%;
    background: url('~@/img/H5/top_bg.jpg');
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
    justify-content: center;
    -webkit-justify-content: center;
    align-items: center;
    -webkit-align-items: center;

    .title {
      font-weight: 700;
      font-size: 16px;
      color: #000;

    }
  }

  .sidemenu {
    &__btn {
      position: absolute;
      right: 0;
      top: 0;
      display: block;
      width: 40px;
      height: 50px;
      border: none;
      z-index: 100;
      appearance: none;
      cursor: pointer;
      outline: none;

      span {
        display: block;
        width: 22px;
        height: 2px;
        margin: auto;
        background: #000;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transition: all .4s ease;

        &.top {
          transform: translateY(-5px);
        }
        &.bottom {
          transform: translateY(5px);
        }
      }

      &.active {
        left: 0;

        .top {
          transform: rotate(-40deg);
          top: -8px;
          width: 12px;
        }

        .mid {
          transform: translateX(0px) rotate(360deg);
          opacity: 0;
        }

        .bottom {
          transform: rotate(40deg);
          top: 5px;
          width: 12px;
        }
      }

    }
  }

}
</style>