<template>
  <div class="lotteryCard_type1">
    <div v-if="lotteryConfig[lotteryCode]">
      <a :href="`/#/lottery/${lotteryCode}`" class="top">
        <img class="icon" :src="require('@/img/ballIcon/'+ lotteryConfig[lotteryCode].imgUrl)">
        <div class="lottery_info">
          <div class="lottery_name">
            {{lotteryName}}
          </div>
          <div class="draw_number">
            {{lastPeriod | formatComingLotteryWithDate(lastDrawTime, lotteryCode, lastPeriod)}}
          </div>
        </div>
        <div class="timer">
          <div class="period">
            {{currentPeriod | formatComingLottery(currentDrawTime, lotteryCode, lastPeriod)}}
          </div>
          <div class="value" v-if="isLowOpen == false">
            {{timerCountDown | formatReciprocal}}
          </div>
          <div class="value" v-else>
            {{timerCountDown | formatReciprocalNoHour}}
          </div>
          <div class="desc" :class="isLowOpen == true ? 'no-hour':''">
            <span v-if="isLowOpen == false">天</span>
            <span v-if="isLowOpen == false">时</span>
            <span >分</span>
            <span>秒</span>
          </div>
        </div>
      </a>
      <div class="info">
        <div class="bottom">
          <div class="draw_number" v-if="lastDraw.length>1">
            <div v-for="(item, index) in lastDraw"
                 :key="'lastDraw' + index"
                 :class="`${lotteryCode} ball ball-${parseInt(item).toString().substring(-1)}`">
              <span v-if="padLeftLottery.includes(lotteryCode)">{{item | padLeft(2)}}</span>
              <span v-else-if="padLeftLottery2.includes(lotteryCode)">{{parseInt(item)}}</span>
              <span v-else>{{item}}</span>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import { mapState, mapGetters, mapActions } from 'vuex';
    import {lotteryConfig} from "@/assets/lotteryConfig.js";

    export default {
        name: 'LotteryCard_type1',
        props: {
            msg: String,
            lotteryCode: {
                type: String,
                default: ""
            },
          loaded:{
              type: Boolean,
              default: false
          }
        },
        data() {
            return {
                timerCountDown: 0,
                lotteryName: "",
                lastPeriod: "",
                lastDraw: [],
                lastDrawTime: "",
                currentPeriod: "",
                currentDrawTime: "",
                lotteryConfig: lotteryConfig,
                padLeftLottery: ['azxy8','jisukuaile','lucky7daily', 'infinity8', 'infinity8ex', 'cannon20', 'cannon20ex', 'lucky5', 'lucky5ex', 'xglhc', 'kl8lhc'],
                padLeftLottery2: ['azxy10','azxy20','jisusaiche']
            }
        },
        computed: {
            ...mapState([
                "baseInfo",
                "lotteryDrawTime"
            ]),
            isForeign: function(){
              return this.lotteryConfig[this.lotteryCode].displayperiodType == 'foreign'
            },
            isLowOpen: function(){
              return this.lotteryConfig[this.lotteryCode].lotteryType == 1
            }
            
	    },
        watch: {
            lotteryDrawTime: {
                handler: function(value){
                    let time = value[this.lotteryCode];
                    if(time)
                    this.timerCountDown = time;
                },
                immediate: true,
                deep: true,
            },
            baseInfo: {
                handler: function(value){
                    this.setLotteryInfo(this.lotteryCode);                   
                },
                immediate: true,
                deep: true,
            },
        },
        methods: {
            setLotteryInfo: function(lotteryCode) {

                if(!this.lotteryConfig[lotteryCode]) {
                    return;
                }
                let lotteryInfo = this.baseInfo[lotteryCode];
                let lastperiodInfo = this.baseInfo[lotteryCode].last_period;
                let currentperiodInfo = this.baseInfo[lotteryCode].current_period;
                try {
                    if (lastperiodInfo) {
                      this.lotteryName = this.lotteryConfig[lotteryCode].name;
                      this.lastPeriod = lastperiodInfo.period_no;
                      this.currentPeriod = currentperiodInfo.period_no;
                      this.currentDrawTime = currentperiodInfo.draw_time;
                      this.lastDraw = this.$options.filters.splitDrawNumber(lastperiodInfo.draw.split(","),this.lotteryCode);
                      this.lastDrawTime = lastperiodInfo.draw_time;
                    }

                }catch(err){
                  //console.log(lotteryCode)
                }
            },
            getIntNumStyle(num){
                return parseInt(num)
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.setLotteryInfo(this.lotteryCode);
            }); 
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .lotteryCard_type1 {
        margin-top: 10px;
        padding-bottom: 10px;
        display: flex;
        color: #001236;
        border-bottom: 0.5px #aab2ba solid;

        .icon {
            width: 55px;
            height: 55px;
        }
        .top {
          display: flex;
          align-items: center;
          display: -webkit-flex;
          -webkit-align-items: center;
          justify-content: space-between;

          .lottery_info {
            .lottery_name {
              margin-top: 10px;
              margin-left:15px;
              width: 120px;
              height: auto;
              font-size: 15px;
              text-align: left;
              color: #2b2b2b;
              font-weight: bold;
            }

            .draw_number {
              height: 17px;
              margin: 6px 0 0 15px;
              font-family: "Open Sans";
              font-size: 12px;
              font-weight: 100;
              text-align: left;
              color: #2b2b2b;
            }
          }

          .timer {

            .period{

              font-size: 10px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: right;
              color: #2b2b2b;
            }

            .value {
              height: 17px;
              font-family: "Open Sans";
              font-size: 17px;
              font-weight: 300;
              text-align: right;
              color: #2b2b2b;
            }

            .desc {
              height: 15px;
              font-size: 12px;
              color: #2b2b2b;
              transform: scale(0.9);
              transform-origin: left center;
              display: flex;
              justify-content: space-between;
              left: 10px;
              width: 98%;
              position: relative;
              &.no-hour{
                width: 42%;
                margin-left: auto;
                left: 0;
              }
            }
          }

        }

        .info {
          margin-top: 5px;
            .bottom {
                margin-top: 10px;

                .draw_number {
                   margin-bottom: 14px;
                    .ball {
                        text-align:center;
                        color:#fff;
                    }
                }
            }
        }
    }
</style>