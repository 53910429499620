<template>
    <div v-if="dataList">
        <div class="header">
            {{dataInfor.dataTitle }}（{{dataLength}}期）
        </div>

        <table width="100%" style="table-layout:fixed" v-if="dataList">
             <!-- 單層 -->
            <tr class="title" v-if="!Array.isArray(dataList[dataInfor.mapApiKey])">
                <td v-for="(item,index) in ['期數'].concat(Object.keys(dataList[dataInfor.mapApiKey]))" :key="index">{{item}}</td>
            </tr>
            <!-- 雙層 -->
            <tr class="title" v-else>
                <td v-for="(item,index) in ['期數'].concat(Object.keys(dataList[dataInfor.mapApiKey][ballIndex]))" :key="index">{{item}}</td>
            </tr>
            <!-- 大小单双 table -->
            <template  v-if="dataInfor.mapApiKey == 'changlong_two_sides'">
                <!--dataList['大'].length:主要是拿期數 -->
                <tr v-for="(item,index) in dataList['changlong_two_sides'][ballIndex]['单'].length" :key="index" class="result_list">
                    <td>{{index+2}} 期</td>
                    <td>{{dataList['changlong_two_sides'][ballIndex]["大"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_two_sides'][ballIndex]["小"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_two_sides'][ballIndex]["单"][index] | formatLongReminderValue}}</td>
                    <td>{{dataList['changlong_two_sides'][ballIndex]["双"][index] | formatLongReminderValue}}</td>
                </tr> 
            </template>
        </table> 
    </div>  
</template>
<script>
import {longReminderTableConfig} from "@/assets/longReminderTableConfig.js"
import { getLotteryLongReminder } from '@/api/api';
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
    props:{
        dataInfor:{
            type: Object,
        },
        lotteryCode: {
            type: String,
            default: "qxc"
        },
        dataLength: {
            type: String,
            default: "100"
        },
        ballIndex: {
            type: Number,
            default: 0
        },
    },
    computed: {
        ...mapState([
            "baseInfo",
            "lotteryDrawTime"
        ]),
    },
    watch: {
        dataLength: function (val) {
            this.dataFormat();
        },
    },
    data(){
        return{
            dataList: null,
            timer: null,
            apiResult: null,
        }
    },
    methods:{
        async getInit(){
            try{
                this.apiResult = await getLotteryLongReminder(this.lotteryCode);
                this.dataFormat();
            }catch(e){
                
            }
        },
        dataFormat() {
            try{
                let result = this.apiResult;
                let display_data = result[this.dataLength];
                this.dataList = display_data;
            }catch(e){
                
            }
        }
    },
    async mounted(){
        this.getInit();
        this.timer = setInterval(()=>{
            if(this.lotteryDrawTime[this.lotteryCode] <= 0) {
                this.getInit();                
            }
        },1000);
    },
   destroyed() {
        clearInterval(this.timer);
    }
}

</script>
<style lang="scss" scoped>
@import '@/style/_longReminder.scss';
</style>
