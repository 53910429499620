<template>
    <div> 
        <table>
          <tr class="header-title">
            <td class="time">时间</td>
            <td class="period">期数</td>
            <td class="result">
              <div class="container" v-if="dataList[0] && (dataList[0].ShowBigSmall || dataList[0].ShowSingleDouble)">
                <button class="changeBtn" :class="{ active: resultType==0  }" @click="resultType=0">显示号码</button>
                <button v-if="dataList[0] && dataList[0].ShowBigSmall" class="changeBtn" :class="{ active: resultType==1 }" @click="resultType=1">显示大小</button>
                <button v-if="dataList[0] && dataList[0].ShowSingleDouble" class="changeBtn" :class="{ active: resultType==2 }" @click="resultType=2">显示单双</button>
                <button v-if="dataList[0] && dataList[0].ShowParallel" class="changeBtn" :class="{ active: resultType==3 }" @click="resultType=3">显示对子</button>
              </div>
              <template v-else>显示号码</template>
            </td>
            <td v-if="dataList[0] && dataList[0].TotalSum" class="zh" style="width:180px;">总和</td>
            <td v-if="dataList[0] && dataList[0].DragonTiger" class="lh">龙虎</td>
            <!-- -->
            <td v-if="dataList[0] && dataList[0].TailBigSmall" class="lh">尾大小</td>
            <td v-if="dataList[0] && dataList[0].OneAndFourDragonTiger" class="lh">1~4龙虎</td>

            <!-- -->
            <td v-if="dataList[0] && dataList[0].TopTwiceSum" class="zh">冠亚军和</td>
            <td v-if="dataList[0] && dataList[0].OneAndFiveDragonTiger" class="lh">1~5龙虎</td>
            <!-- -->
            <td v-if="dataList[0] && dataList[0].FishShrimpAndCrab" class="lh">鱼蟹虾</td>
            <!-- -->
            <td v-if="dataList[0] && dataList[0].TopThree" class="lh">前三</td>
            <td v-if="dataList[0] && dataList[0].MiddleThree" class="lh">中三</td>
            <td v-if="dataList[0] && dataList[0].LastThree" class="lh">后三</td>

            <!-- -->
            <td v-if="dataList[0] && dataList[0].SingleDoubleTie" class="lh">单双</td>
            <td v-if="dataList[0] && dataList[0].FrontBackendTie" class="lh">前后</td>
            <td v-if="dataList[0] && dataList[0].MultiMarkup" class="lh">总和组合</td>
            <td v-if="dataList[0] && dataList[0].FiveElements" class="lh">五行</td>
          </tr>
            <!--- 歷史結果列表 --->
          <tr v-for="(item,index) in dataList" :key="index" class="history_item">
              <td v-if="item.draw_time">{{item.draw_time[0] | formateDrawTimeAll}}</td>
              <td v-if="item.period_no">{{item.period_no[0]}}</td>
              <td>
                <div v-if="resultType===0" :class="`ball-wrapper ${LotteryTwenty.includes(lotteryCode) ? 'twentyBall' : ''} `">
                  <div
                      v-for="(num,index) in $options.filters.splitDrawNumber(item.ShowNumber,lotteryCode)"
                      :class="`${lotteryCode} ball ball-${parseInt(num)} ${LotteryTwenty.includes(lotteryCode) ? 'twentyBall' : ''}  solid `"
                      :key="index">
                    <span v-if="padLeftLottery.includes(lotteryCode)">{{num | padLeft(2)}}</span>
                    <span v-else-if="padLeftLottery2.includes(lotteryCode)">{{parseInt(num)}}</span>
                    <span v-else>{{num}} </span>
                  </div>
                </div>

                <div v-if="resultType===1 && item.ShowBigSmall.length > 0" :class="`ball-wrapper ${LotteryTwenty.includes(lotteryCode) ? 'twentyBall' : ''} `">
                  <div v-for="(option,index) in item.ShowBigSmall" :key="index" :class="`ball ${styleFilter(option)}`" >
                    <span>{{option}}</span>
                  </div>
                </div>
                <div v-if="resultType===2 && item.ShowSingleDouble.length > 0" :class="`ball-wrapper ${LotteryTwenty.includes(lotteryCode) ? 'twentyBall' : ''} `">
                  <div  v-for="(option,index) in item.ShowSingleDouble" :key="index" :class="`ball ${styleFilter(option)}`" >
                    <span>{{option}}</span>
                  </div>
                </div>
                <div v-if="resultType==3" :class="`ball-wrapper ${LotteryTwenty.includes(lotteryCode) ? 'twentyBall' : ''} `">
                  <div :class="`box  ball ${item.ShowParallel.includes(num) ? `${lotteryCode}   ball-${parseInt(num)} color-white ` : 'color-black no-style' } ${LotteryTwenty.includes(lotteryCode) ? 'twentyBall' : ''}  solid `"  v-for="(num,index) in $options.filters.splitDrawNumber(item.ShowNumber, lotteryCode)" :key="index">
                    <span v-if="padLeftLottery.includes(lotteryCode)">{{num | padLeft(2)}}</span>
                    <span v-else-if="padLeftLottery2.includes(lotteryCode)">{{parseInt(num)}}</span>
                    <span v-else>{{num}}</span>
                  </div>
                </div>
              </td>
              <td v-if="dataList[0] && dataList[0].TotalSum" style="position: relative;">
                <div class="internal-wrapper">
                  <table class="internal" >
                    <tr>
                      <td v-for="(option,index) in item.TotalSum" :key="index" :class="`${$options.filters.drawsFilter(option)}`">{{option}}</td>
                    </tr>
                  </table>
                </div>
              </td>
              <td v-if="dataList[0] && dataList[0].DragonTiger" style="position: relative;">
                <div class="internal-wrapper">
                  <table class="internal" >
                    <tr>
                      <td v-for="(option,index) in item.DragonTiger" :key="index" :class="`${$options.filters.drawsFilter(option)}`">{{option}}</td>
                    </tr>
                  </table>
                </div>
              </td>

              <td v-if="dataList[0] && dataList[0].TopTwiceSum" style="position: relative;">
                <div class="internal-wrapper">
                  <table class="internal" >
                    <tr>
                      <td v-for="(option,index) in item.TopTwiceSum" :key="index" :class="`${$options.filters.drawsFilter(option)}`">{{option}}</td>
                    </tr>
                  </table>
                </div>
              </td>
              <td v-if="dataList[0] && dataList[0].OneAndFiveDragonTiger" style="position: relative;">
                <div class="internal-wrapper">
                  <table class="internal" >
                    <tr>
                      <td v-for="(option,index) in item.OneAndFiveDragonTiger" :key="index" :class="`${$options.filters.drawsFilter(option)}`">{{option}}</td>
                    </tr>
                  </table>
                </div>
              </td>
              <td v-if="dataList[0] && dataList[0].FishShrimpAndCrab" style="position: relative;">
                <div class="internal-wrapper">
                  <table class="internal" >
                    <tr>
                      <td v-for="(option,index) in item.FishShrimpAndCrab" :key="index" :class="`${$options.filters.drawsFilter(option)}`">{{option}}</td>
                    </tr>
                  </table>
                </div>
              </td>

              <td v-if="dataList[0] && dataList[0].TopThree" style="position: relative;">
                <div class="internal-wrapper">
                  <table class="internal" >
                    <tr>
                      <td v-for="(option,index) in item.TopThree" :key="index" :class="`${$options.filters.drawsFilter(option)}`">{{option}}</td>
                    </tr>
                  </table>
                </div>
              </td>
              <td v-if="dataList[0] && dataList[0].MiddleThree" style="position: relative;">
                <div class="internal-wrapper">
                  <table class="internal" >
                    <tr>
                      <td v-for="(option,index) in item.MiddleThree" :key="index" :class="`${$options.filters.drawsFilter(option)}`">{{option}}</td>
                    </tr>
                  </table>
                </div>
              </td>
              <td v-if="dataList[0] && dataList[0].LastThree" style="position: relative;">
                <div class="internal-wrapper">
                  <table class="internal" >
                    <tr>
                      <td v-for="(option,index) in item.LastThree" :key="index" :class="`${$options.filters.drawsFilter(option)}`">{{option}}</td>
                    </tr>
                  </table>
                </div>
              </td>
              <td v-if="dataList[0] && dataList[0].SingleDoubleTie" style="position: relative;">
                <div class="internal-wrapper">
                  <table class="internal" >
                    <tr>
                      <td v-for="(option,index) in item.SingleDoubleTie" :key="index" :class="`${$options.filters.drawsFilter(option)}`">{{option}}</td>
                    </tr>
                  </table>
                </div>
              </td>
              <td v-if="dataList[0] && dataList[0].FrontBackendTie" style="position: relative;">
                <div class="internal-wrapper">
                  <table class="internal" >
                    <tr>
                      <td v-for="(option,index) in item.FrontBackendTie" :key="index" :class="`${$options.filters.drawsFilter(option)}`">{{option}}</td>
                    </tr>
                  </table>
                </div>
              </td>
              <td v-if="dataList[0] && dataList[0].MultiMarkup" style="position: relative;">
                <div class="internal-wrapper">
                  <table class="internal" >
                    <tr>
                      <td v-for="(option,index) in item.MultiMarkup" :key="index" :class="`${$options.filters.drawsFilter(option)}`">{{option}}</td>
                    </tr>
                  </table>
                </div>
              </td>
              <td v-if="dataList[0] && dataList[0].FiveElements" style="position: relative;">
                <div class="internal-wrapper">
                  <table class="internal" >
                    <tr>
                      <td v-for="(option,index) in item.FiveElements" :key="index" :class="`${$options.filters.drawsFilter(option)}`">{{option}}</td>
                    </tr>
                  </table>
                </div>
              </td>
              <td v-if="dataList[0] && dataList[0].TailBigSmall" style="position: relative;">
                <div class="internal-wrapper">
                  <table class="internal" >
                    <tr>
                      <td v-for="(option,index) in item.TailBigSmall" :key="index" :class="`${$options.filters.drawsFilter(option.slice(1))}`">{{option.slice(1)}}</td>
                    </tr>
                  </table>
                </div>
              </td>
              <td v-if="dataList[0] && dataList[0].OneAndFourDragonTiger" style="position: relative;">
              <div class="internal-wrapper">
                <table class="internal" >
                  <tr>
                    <td v-for="(option,index) in item.OneAndFourDragonTiger" :key="index" :class="`${$options.filters.drawsFilter(option)}`">{{option}}</td>
                  </tr>
                </table>
              </div>
            </td>
            </tr>

        </table>
    </div>
</template>
<script>
export default {
  props:{
      dataList:Array,
      lotteryCode:String
  },
  data(){
    return {
      padLeftLottery: ['azxy8','jisukuaile','lucky7daily', 'infinity8', 'infinity8ex', 'cannon20', 'cannon20ex', 'lucky5', 'lucky5ex', 'xglhc', 'kl8lhc'],
      LotteryTwenty:['cannon20','azxy20','cannon20ex'],
      padLeftLottery2: ['azxy10','azxy20','jisusaiche'],
        resultType: 0,
    }
  },
  methods:{
    styleFilter: function (value) {
      if(value ==='单' || value === '小'){
        return 'lightblue';
      }else if(value ==='双' || value === '大'){
        return 'blue';
      }else{
        return 'yellow';
      }
    }
  },
  watch: {
    '$route': {
      handler: function(to, from) {
        this.resultType=0;
      },
      immediate: true
    }
  }
}

</script>
<style lang="scss" scoped>

.header-title{
    background: #203d7f;
    color: #ffe6cc;
    height: 70px;
    td{
        border: 1px solid #a2bdec;
        font-size: 18px;
    }
    .drawNum{
        width: 567px;
    }
  .time{width:200px;}
  .period{width:180px;}
    .result{
        width: 480px;
        .container{
          display:flex;
          justify-content: center;
        }
    }
}


/** 歷史列表
******************************/
.history_item{
    height: 73px;
    font-size: 14px;
    color: #646363;
    &:nth-child(even) {
        background: #d0dce8
    }
    .ball-wrapper{
        display: flex;
        justify-content: center;
        padding:10px;
        .ball{
          color: #fff;
          margin:5px;
        }
      &.twentyBall{
        padding:10px;
        display: grid;
        grid-template-columns: repeat(11, 1fr);
      }

      .ball:nth-child(10) {
        grid-column: span 2;
      }
      .ball {
        @include ballSize(45px);
        margin-right: 2px;
        font-size: 30px;
        grid-column: span 1;
        color: #fff;
      }
      .box {
        @include ballSize(45px);
        margin-right: 2px;
        border-radius: 4px;
        font-size: 30px;
        grid-column: span 1;

      }
    }
     .sum__wrapper{
        display: flex;
        .text{
           
            width: 50%;
            &:last-child{
                font-weight: 600;
            }
        }
        
    } 
    td{
        border: 1px solid #a2bdec;
        font-size: 14px;
    }

}
.sum_result{
  span {
    display: table-cell;
    width:1%;
    font-size:14px;
  }
}

.result {
  .changeBtn {
    margin: 0 10px;
    border: 0;
    font-size: 18px;
    font-weight: bold;
    background: transparent;
    color: rgba(255, 223, 199, 0.3);
    cursor: pointer;

    &.active {
      color: #ffdfc7;
    }
  }
  tr {
    td {
      border: 1px solid #a2bdec;
    }
  }
}

// 內嵌table
.internal {
  table-layout: fixed;
  border: none;
  border-collapse: collapse;
  width:100%;
  height:100%;
}
.internal td,
.internal th {
  border-color: yellow;
}
.internal tr:first-child th {
  border-top: none;
}
.internal tr:last-child td {
  border-bottom: none;
  border-top: none;
}
.internal tr td:first-child,
.internal tr th:first-child {
  border-left: none;
}
.internal tr td:last-child,
.internal tr th:last-child {
  border-right: none;
}

.internal-wrapper {

  position: absolute;
  height: 100%;
  top: 0;
  td {
    width: 1%;
  }
  .internal{
    height:100%;
  }
}
.no-style{background: transparent;color:#000!important;}
</style>
