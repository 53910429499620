<template>
    <div v-if="dataInfor">
        <div class="result" v-for="(item,index) in dataInfor.dataList" :key="index">
            <div class="table-header">
                <div class="left-text">{{headerText[index]}} </div>
                <!--單雙---->
                <div class="right-text" v-if="dataInfor.mapApiKey=='draw_no_odds_even_road_bead'">累计 :
                    <span>单{{dataInfor.flateData[index]| analysisCaulPearl('单')}}</span>
                    <span style="margin-left:10px">双{{dataInfor.flateData[index]| analysisCaulPearl('双')}}</span>
                </div>
                <!--大小---->
                <div class="right-text" v-if="dataInfor.mapApiKey=='draw_no_big_small_road_bead'">累计 :
                    <span>大{{dataInfor.flateData[index]| analysisCaulPearl('大')}}</span>
                    <span style="margin-left:10px">小{{dataInfor.flateData[index]| analysisCaulPearl('小')}}</span>
                </div>
                <!---波色--->
                <div class="right-text" v-if="dataInfor.mapApiKey=='draw_no_color_road_bead'">累计 :
                    <span>红{{dataInfor.flateData[index]| analysisCaulPearl('红')}}</span>
                    <span style="margin-left:10px">蓝{{dataInfor.flateData[index]| analysisCaulPearl('蓝')}}</span>
                    <span style="margin-left:10px">绿{{dataInfor.flateData[index]| analysisCaulPearl('绿')}}</span>
                </div>
            </div> 
            <div class="wrapper">
                <table width="100%" style="table-layout:fixed">
                    <tr>
                        <!-- isNeedReverse(item) -->
                        <td v-for="(obj,index) in isNeedReverse(item)" :key="index">
                            <p 
                                v-for="(p,i) in obj" :key="i" 
                                :class="{
                                    'even':p==='双',
                                    'big':p==='大',
                                    'green':p==='绿',
                                    'red':p==='红', 
                                    'blue':p==='蓝', 
                                }">
                                {{p}}
                            </p>
                        </td>
                        <!---這三欄要空白-->
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import testData from "@/assets/test2.json";
// import GameDetailSubBtn from "@/components/common/GameDetailSubBtn"
export default {
    // components:{
    //     GameDetailSubBtn
    // },
  
    props:{
        dataInfor:{
           typeof:Object
        }
    },
    data(){
        return{
           headerText:['正码1','正码2','正码3','正码4','正码5','特码'],
           needBlank:0 // 需要補的空格
        }
    },
    methods: {
        isNeedReverse(data){
           if(data.length > 30){
                return data.slice(0,30).reverse();
            }else{
                var tempData = [];
                for(let i = 0; i < data.length; i++){
                    tempData[tempData.length] = data[i];
                }
                tempData.reverse();
                tempData.length =30;
                return tempData;
            } 
        }
        
    },
    mounted(){
     
       
    }
   
}
</script>
<style lang="scss" scoped>
.result:last-child>.table-header{
    background-color: #f0cbcb;
}

.table-header{
    width: 100%;
    height: 70px;
    background-color: #c3d3e3;
    font-size: 17px;
    display: flex;
    line-height: 70px;
    padding: 0 32px;
    box-sizing: border-box;
    justify-content: flex-start;
    margin-top: 40px;
    .left-text{
        color: #0f2957;
        margin-right: 30px;
    }   
    .right-text{
        color: #0f2957;
    }
   
}
.wrapper{
    table{  
        td{  
            padding: 10px 0 35px 0;
            vertical-align: top;
            &:nth-child(odd){
                background: #f0f0f0;
            }
            p{  
                font-size: 17px;
                line-height: 22px;
                color: #646363;
                &.even,&.big{
                    color: #df0d15; 
                }
                &.green{
                   margin: 0 auto 3px auto;
                    text-indent: 999999px;
                    width: 20px;
                    height: 20px;
                    border-radius: 50px;
                    border:2px solid #23b439;
                }
                &.red{
                    margin: 0 auto 3px auto;
                    width: 20px;
                    height: 20px;
                    text-indent: 999999px;
                    border-radius: 50px;
                    border:2px solid #df0d15;
                }
                &.blue{
                    margin: 0 auto 3px auto;
                    width: 20px;
                    height: 20px;
                    text-indent: 999999px;
                    border-radius: 50px;
                    border:2px solid #0380d8;
                }
                
            }
        }
    }
}
</style>
