<template>
    <div class="disclaimer">
        <div class="content">
            <div class="bookmark_row">
                <div class="bookmark disable" @click="changePage(0)">
                    关于我们
                </div>
                <div class="bookmark">
                    免责声明
                </div>
                <div class="bookmark disable" @click="changePage(2)">
                    玩法规则
                </div>
            </div>
            <div class="text_box">
                <h1>
                    百万富翁资讯网提醒您：
                </h1>
                <br>
                <p>
                    在使用百万富翁资讯网之前，请您务必仔细阅读并透彻理解本声明。您可以选择不使用本资讯网，但如果您使用本资讯网，您的使用行为将被视为对本声明全部内容的认可。
                </p>
                <p>
                    除百万富翁资讯网注明之服务条款外，其他一切因使用本资讯网而可能遭致的意外、疏忽、侵权及其造成的损失（包括因下载被搜索链接到的第三方网站内容而感染电脑病毒），本资讯网对其概不负责，亦不承担任何法律责任。
                </p>
                <p>
                    任何通过使用百万富翁资讯网而链接到的第三方网页均系他人制作或提供，您可能从该第三方网页上获得资讯及享用服务，本资讯网对其合法性概不负责，亦不承担任何法律责任。
                </p>
                <p>
                    任何单位或个人认为通过百万富翁资讯网链接到的第三方网页内容可能涉嫌侵犯其信息网络传播权，应该及时向本资讯网提出书面权利通知，并提供身份证明、权属证明及详细侵权情况证明。本资讯网在收到上述法律文件后，将会依法尽快断开相关链接内容。
                </p>
                <p>
                    用户明确同意其使用本网站网络服务所存在的风险将完全由其自己承担；因其使用本网站网络服务而产生的一切后果也由其自己承担，百万富翁资讯网对用户不承担任何责任。
                </p>
                <br>
                <h1>
                    下列情况发生时，本网站亦毋需承担任何责任：
                </h1>
                <br>
                <p>
                    1、任何由于黑客攻击、计算机病毒侵入或发作、因政府管理而造成的暂时性关闭，等影响网络正常经营之不可抗力，而造成的个人资料泄露、丢失、被盗用或被篡改等。
                </p>
                <p>
                    2、由于与本网站链接的其它网站，所造成之个人资料泄露，及由此而导致的任何法律争议和后果。
                </p>
                <p>
                    3、本站各项内容，除标明“百万富翁资讯网出品”外，其余内容皆由签约内容服务提供商（机构）、网友提供，本公司不承担由于内容的合法性及健康性，所引起的一切争议和法律责任。如有疑异，请与本公司客服中心联系，我们将尽快处理。
                </p>
                <p>
                    4、本网站网页上有与其他网站网页的链接，包括旗帜广告和网页上的其他广告。本网对其他任何网站的内容、隐私政策或运营，或经营这些网站的公司的行为概不负责。在向这些与本网链接的网站提供个人信息之前，请查阅它们的隐私政策。
                </p>
                <p>
                    5、本站所有帖子、评论仅代表作者个人观点，不代表本站。
                </p>
                <br>
                <p>
                    欢迎大家对侵犯版权等不合法和不健康的内容进行监督和举报。
                </p>
            </div>
        </div>
        <!--新聞-->
        <NewsCollection />
    </div>
</template>

<script>
    import NewsCollection from '@/components/common/NewsCollection.vue'
    
    export default {
        name: 'Disclaimer',
        components: {
            NewsCollection
        },
        data() {
            return {
                pageUrl: [
                    "aboutus", "disclaimer", "gamerule"
                ],
            }
        },
        computed: {
        },
        methods: {
            changePage (i) {
                this.$router.push(this.pageUrl[i]);
            },
        },
        mounted() {
        }
    }
</script>

<style scoped lang="scss">

</style>