export const lotteryConfig = {
    xglhc:{
        name: "香港六合彩",
        imgUrl:"hklhc_icon.png",
        abroad:false,
        country: 'CN',
        lotteryType:1, 
        displayperiodType: "nationwide",
        liveStream: true,
        liveStreamConfig: {
            liveStartTimeHour: 21,
            liveStartTimeMin: 22,
            liveEndTimeHour: 21,
            liveEndTimeMin: 45,
        },
        mainMenu:{
            history:{
                name:"开奖历史",
                iconName:"history",
                subMenu:[],
            },
            imageLib:{
                name:"图纸图库",
                iconName:"",
                subMenu:[],
                otherBtn:[]
            }
        }
    },
    kl8lhc:{
        name: "福彩快8六合彩",
        imgUrl:"kl8lhc_icon.png",
        abroad:false,
        country: 'CN',
        lotteryType:1,
        displayperiodType: "nationwide",
        liveStream: true,
        liveStreamConfig: {
            liveStartTimeHour: 21,
            liveStartTimeMin: 22,
            liveEndTimeHour: 21,
            liveEndTimeMin: 45,
        },
        mainMenu:{
            history:{
                name:"开奖历史",
                iconName:"history",
                subMenu:[],
            },
            imageLib:{
                name:"图纸图库",
                iconName:"",
                subMenu:[],
                otherBtn:[]
            }
        }
    },
    lucky7daily:{
        name: "英国天天彩",
        imgUrl:"lucky7daily_icon.png",
        abroad:true,
        country: 'GBT',
        lotteryType:1,
        displayperiodType: "foreign",
        liveStream: false,
        liveStreamConfig: {
            liveStartTimeHour: 21,
            liveStartTimeMin: 22,
            liveEndTimeHour: 21,
            liveEndTimeMin: 45,
        },
        mainMenu:{
            history:{
                name:"开奖历史",
                iconName:"history",
                subMenu:[],
                otherBtn:[]
            },
            imageLib:{
                name:"图纸图库",
                iconName:"",
                subMenu:[],
                otherBtn:[]
            }
        }
    },
    pl5daily:{
        name: "英国五球彩",
        imgUrl:"pl5daily_icon.png",
        abroad:true,
        country: 'GBT',
        lotteryType:1,
        displayperiodType: "foreign",
        liveStream: false,
        mainMenu:{
            history:{
                name:"开奖历史",
                iconName:"history",
                subMenu:[],
                otherBtn:[]
            }
        }
    },
    qxc:{
        name: "七星彩",
        imgUrl:"qxc_icon.png",
        abroad:false,
        country: 'CN',
        lotteryType:1, 
        displayperiodType: "nationwide",
        liveStream: false,
        mainMenu:{
            history:{
                name:"开奖历史",
                iconName:"history",
                subMenu:[],
                otherBtn:[]
            }
        }
    },
    pl3:{
        name: "排列三",
        imgUrl:"pailie3_icon.png",
        abroad:false,
        country: 'CN',
        lotteryType:1, 
        displayperiodType: "nationwide",
        liveStream: false,
        mainMenu:{
            history:{
                name:"开奖历史",
                iconName:"history",
                subMenu:[],
                otherBtn:[]
            }
        }
    },
    pl5:{
        name: "排列五",
        imgUrl:"pailie5_icon.png",
        abroad:false,
        country: 'CN',
        lotteryType:1, 
        displayperiodType: "nationwide",
        liveStream: false,
        mainMenu:{
            history:{
                name:"开奖历史",
                iconName:"history",
                subMenu:[],
                otherBtn:[]
            }
        }
    },
    rolling10:{
        name: "英国赛车",
        imgUrl:"rolling10_icon.png",
        abroad:true,
        country: 'GB',
        lotteryType:2,
        displayperiodType: "foreign",
        liveStream: false,
        mainMenu:{
            history:{
                name:"开奖历史",
                iconName:"history",
                subMenu:[],
                otherBtn:[]
            }
        }
    },
    penta5:{
        name: "英国时时彩",
        imgUrl:"penta5_icon.png",
        abroad:true,
        country: 'GB',
        lotteryType:2,
        displayperiodType: "foreign",
        liveStream: false,
        mainMenu:{
            history:{
                name:"开奖历史",
                iconName:"history",
                subMenu:[],
                otherBtn:[]
            }
        }
    },
    infinity8:{
        name: "英国快乐彩",
        imgUrl:"infinity8_icon.png",
        abroad:true,
        country: 'GB',
        lotteryType:2,
        displayperiodType: "foreign",
        liveStream: false,
        mainMenu:{
            history:{
                name:"开奖历史",
                iconName:"history",
                subMenu:[],
                otherBtn:[]
            }
        }
    },
    rolling10ex:{
        name: "英国极速赛车",
        imgUrl:"rolling10ex_icon.png",
        abroad:true,
        country: 'GB',
        lotteryType:2,
        displayperiodType: "foreign",
        liveStream: false,
        mainMenu:{
            history:{
                name:"开奖历史",
                iconName:"history",
                subMenu:[],
                otherBtn:[]
            }
        }
    },
    penta5ex:{
        name: "英国极速时时彩",
        imgUrl:"penta5ex_icon.png",
        abroad:true,
        country: 'GB',
        lotteryType:2,
        displayperiodType: "foreign",
        liveStream: false,
        mainMenu:{
            history:{
                name:"开奖历史",
                iconName:"history",
                subMenu:[],
                otherBtn:[]
            }
        }
    },
    infinity8ex:{
        name: "英国极速快乐彩",
        imgUrl:"infinity8ex_icon.png",
        abroad:true,
        country: 'GB',
        lotteryType:2,
        displayperiodType: "foreign",
        liveStream: false,
        mainMenu:{
            history:{
                name:"开奖历史",
                iconName:"history",
                subMenu:[],
                otherBtn:[]
            }
        }
    },
    penta5classic:{
        name: "幸运五星彩",
        imgUrl:"penta5classic_icon.png",
        abroad:true,
        country: 'GB',
        lotteryType:2, 
        displayperiodType: "foreign",
        liveStream: false,
        mainMenu:{
            history:{
                name:"开奖历史",
                iconName:"history",
                subMenu:[],
                otherBtn:[]
            }
        }
    },
    surfing10classic:{
        name: "英国飞艇",
        imgUrl:"surfing10classic_icon.png",
        abroad:true,
        country: 'GB',
        lotteryType:2,
        displayperiodType: "foreign",
        liveStream: false,
        mainMenu:{
            history:{
                name:"开奖历史",
                iconName:"history",
                subMenu:[],
                otherBtn:[]
            }
        }
    },
    cannon20:{
        name: "英国快乐8",
        imgUrl:"cannon20_icon.png",
        abroad:true,
        country: 'GB',
        lotteryType:2,
        displayperiodType: "foreign",
        liveStream: false,
        mainMenu:{
            history:{
                name:"开奖历史",
                iconName:"history",
                subMenu:[],
                otherBtn:[]
            }
        }
    },
    lucky5:{
        name: "英国11选5",
        imgUrl:"lucky5_icon.png",
        abroad:true,
        country: 'GB',
        lotteryType:2,
        displayperiodType: "foreign",
        liveStream: false,
        mainMenu:{
            history:{
                name:"开奖历史",
                iconName:"history",
                subMenu:[],
                otherBtn:[]
            }
        }
    },
    gamma3:{
        name: "英国快三",
        imgUrl:"gamma3_icon.png",
        abroad:true,
        country: 'GB',
        lotteryType:2,
        displayperiodType: "foreign",
        liveStream: false,
        mainMenu:{
            history:{
                name:"开奖历史",
                iconName:"history",
                subMenu:[],
                otherBtn:[]
            }
        }
    },
    
    cannon20ex:{
        name: "英国极速快乐8",
        imgUrl:"cannon20ex_icon.png",
        abroad:true,
        country: 'GB',
        lotteryType:2,
        displayperiodType: "foreign",
        liveStream: false,
        mainMenu:{
            history:{
                name:"开奖历史",
                iconName:"history",
                subMenu:[],
                otherBtn:[]
            }
        }
    },
    lucky5ex:{
        name: "英国极速11选5",
        imgUrl:"lucky5ex_icon.png",
        abroad:true,
        country: 'GB',
        lotteryType:2,
        displayperiodType: "foreign",
        liveStream: false,
        mainMenu:{
            history:{
                name:"开奖历史",
                iconName:"history",
                subMenu:[],
                otherBtn:[]
            }
        }
    },
    gamma3ex:{
        name: "英国极速快三",
        imgUrl:"gamma3ex_icon.png",
        abroad:true,
        country: 'GB',
        lotteryType:2,
        displayperiodType: "foreign",
        liveStream: false,
        mainMenu:{
            history:{
                name:"开奖历史",
                iconName:"history",
                subMenu:[],
                otherBtn:[]
            }
        }
    },
    azxy5:{
        name: "澳洲幸运5",
        imgUrl:"azxy5_icon.png",
        abroad:true,
        country: 'AU',
        lotteryType:2,
        displayperiodType: "foreign",
        liveStream: false,
        mainMenu:{
            history:{
                name:"开奖历史",
                iconName:"history",
                subMenu:[],
                otherBtn:[]
            }
        }
    },
    azxy8:{
        name: "澳洲幸运8",
        imgUrl:"azxy8_icon.png",
        abroad:true,
        country: 'AU',
        lotteryType:2,
        displayperiodType: "foreign",
        liveStream: false,
        mainMenu:{
            history:{
                name:"开奖历史",
                iconName:"history",
                subMenu:[],
                otherBtn:[]
            }
        }
    },
    azxy10:{
        name: "澳洲幸运10",
        imgUrl:"azxy10_icon.png",
        abroad:true,
        country: 'AU',
        lotteryType:2,
        displayperiodType: "foreign",
        liveStream: false,
        mainMenu:{
            history:{
                name:"开奖历史",
                iconName:"history",
                subMenu:[],
                otherBtn:[]
            }
        }
    },
    azxy20:{
        name: "澳洲幸运20",
        imgUrl:"azxy20.png",
        abroad:true,
        country: 'AU',
        lotteryType:2,
        displayperiodType: "foreign",
        liveStream: false,
        mainMenu:{
            history:{
                name:"开奖历史",
                iconName:"history",
                subMenu:[],
                otherBtn:[]
            }
        }
    },
    jisukuai3:{
        name: "极速快三",
        imgUrl:"jisukuai3_icon.png",
        abroad:true,
        lotteryType:2,
        displayperiodType: "foreign",
        liveStream: false,
        mainMenu:{
            history:{
                name:"开奖历史",
                iconName:"history",
                subMenu:[],
                otherBtn:[]
            }
        }
    },
    jisukuaile:{
        name: "极速快乐十分",
        imgUrl:"jisukuaile_icon.png",
        abroad:true,
        lotteryType:2,
        displayperiodType: "foreign",
        liveStream: false,
        mainMenu:{
            history:{
                name:"开奖历史",
                iconName:"history",
                subMenu:[],
                otherBtn:[]
            }
        }
    },
    jisusaiche:{
        name: "极速赛车",
        imgUrl:"jisusaiche_icon.png",
        abroad:true,
        lotteryType:2,
        displayperiodType: "foreign",
        liveStream: false,
        mainMenu:{
            history:{
                name:"开奖历史",
                iconName:"history",
                subMenu:[],
                otherBtn:[]
            }
        }
    },
    jisushishi:{
        name: "极速时时彩",
        imgUrl:"jisushishi_icon.png",
        abroad:true,
        lotteryType:2,
        displayperiodType: "foreign",
        liveStream: false,
        mainMenu:{
            history:{
                name:"开奖历史",
                iconName:"history",
                subMenu:[],
                otherBtn:[]
            }
        }
    },
}