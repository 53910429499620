<template>
    <div class="about">
        <div class="content">
            <div class="bookmark_row">
                <div class="bookmark">
                    关于我们
                </div>
                <div class="bookmark disable" @click="changePage(1)">
                    免责声明
                </div>
                <div class="bookmark disable" @click="changePage(2)">
                    玩法规则
                </div>
            </div>
            <div class="text_box">
                <p>
                    百万富翁资讯网是一家致力于中国及世界各地公益彩票事业，提供全方位彩票开奖及彩票相关服务的提供商。
                </p>
                <br>
                <br>
                <p>
                    百万富翁资讯网以互联网技术、电信技术、无线应用技术为依托，将创新的技术与传统的彩票产业相结合，面向全球彩票服务市场，提供最快最全的彩票开奖相关服务，包括彩票开奖结果调用、彩票系统的研发、彩票开奖移动客户端，及彩票产品创新等。
                </p>
                <br>
                <br>
                <p>
                    百万富翁资讯网拥有一流的技术研发创新实力以及国内外多年的彩票网站运营及管理经验， 为中国以及全球彩票玩家提供一流的彩票创新产品及优质的服务。百万富翁资讯网本着专业、诚信、服务至上的经营理念， 充分发挥机动灵活的运营及管理机制，伴随着世界互联网及手机互联网时代的腾飞、彩票市场的不断繁荣，和各方客户的忠诚支持，将全面促进新型彩票服务市场的全盛发展。
                </p>
            </div>
        </div>
        <!--新聞-->
        <NewsCollection />
    </div>
</template>

<script>
    import NewsCollection from '@/components/common/NewsCollection.vue'
    
    export default {
        name: 'AboutUs',
        components: {
            NewsCollection
        },
        data() {
            return {
                pageUrl: [
                    "aboutus", "disclaimer", "gamerule"
                ],
            }
        },
        computed: {
        },
        methods: {
            changePage (i) {
                this.$router.push(this.pageUrl[i]);
            },
        },
        mounted() {
        }
    }
</script>

<style scoped lang="scss">
</style>