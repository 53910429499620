<template>
    <div class="table_infor">



        <div class="lottery_sx" v-if="lastperiod.display_info">
            <div v-for="(item,index) in lastperiod.display_info.zodiac.slice(0, 6)"
                 :key="index"
                 class="item">
              {{item}}
            </div>
            <div class="add"></div>
            <div class="item">{{lastperiod.display_info.zodiac[lastperiod.display_info.zodiac.length-1] }}</div>
        </div>
            <div class="lottery_wx" v-if="lastperiod.display_info">
              <div v-for="(item,index) in lastperiod.display_info.five_elements.slice(0, 6)"
                   :key="index"
                   class="item">
                {{item}}
              </div>
              <div class="add"></div>
              <div class="item">{{lastperiod.display_info.five_elements[lastperiod.display_info.five_elements.length-1] }}</div>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        lastperiod:Object
    }
}
</script>
<style lang="scss" scoped>
.table_infor{
    .lottery_sx,.lottery_wx{
      color: #7b7b7b;
      display: grid;
      grid-template-columns: repeat(11,1fr);
        .item{
          text-align: center;
          font-size:18px;
          font-weight: bold;
          width:45px;
          margin:6px 0;
        }
    }
  .add{
    width:12px;
    margin:0 20px;
  }
}
</style>