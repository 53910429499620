<template>
    <div class="draw_number lhc">
         <div v-for="(item,index) in lastDraw.slice(0, 6)"
            :key="index"
            :class="`${lotteryCode} ball ball-${getIntNumStyle(item)}`">
            {{item | padLeft(2)}}
        </div>
        <div class="add">+</div>
        <div :class="`${lotteryCode} ball ball-${getIntNumStyle(lastDraw[lastDraw.length-1])}`">{{lastDraw[lastDraw.length-1]  | padLeft(2)}}</div>
    </div>
</template>
<script>
export default {
    props:{
        lastDraw:Array,
        lotteryCode:''
    },
    components:{
    
    },
    methods: {
        getIntNumStyle(num){
            return parseInt(num)
        }
    },
}
</script>
<style lang="scss" scoped>
.draw_number{
    display: flex;
    margin-bottom: 14px;
  .add{
    font-size:20px;
    width:12px;
    height:45px;
    line-height: 45px;
    margin:0 20px;
  }
}
.lhc{
    .ball:nth-child(6){
        margin-right: 0;
    }         
}

</style>
