<template>
    <div class="lhc">
        <div class='lottery_balls' :class="`${lotteryCode}-wrapper`">
            <template v-if="lotteryCode != 'lucky7daily'">
                <!--英國天天彩需另外處理-->
                <div :class="`${lotteryCode} ball ball-${parseInt(item).toString().substring(-1)}`"
                    v-for="(item,index) in lastDraw" :key="index">
                    <span v-if="padLeftLottery.includes(lotteryCode)">{{item | padLeft(2)}}</span>
                    <span v-else-if="padLeftLottery2.includes(lotteryCode)">{{parseInt(item)}}</span>
                    <span v-else>
                        {{item}}
                    </span>
                </div>
            </template>
            <template v-else>
              <template v-if="reslotteryInfo2.last_period != undefined">
                    <!--七顆球代表全開完, 不另做處理-->
                    <template v-if="reslotteryInfo2.last_period.draw.split(',').length == 7">
                        <div :class="`${lotteryCode} ball ball-${parseInt(item).toString().substring(-1)}`"
                            v-for="(item,index) in reslotteryInfo2.last_period.draw.split(',').slice(0, 6)" :key="index">
                            <span v-if="padLeftLottery.includes(lotteryCode)">{{item | padLeft(2)}}</span>
                        </div>

                      <div class="add" v-if='lastDraw.length === 7'>+</div>
                      <div :class="`${lotteryCode} ball ball-${(lastDraw[6])}`" v-if='lastDraw.length === 7'>{{lastDraw[6]  | padLeft(2)}}</div>
                    </template>
                    <!--有球未取完-->
                    <template v-else>
                        <template v-if="reslotteryInfo2.last_period.draw.split(',')[0] == ''">
                            <div :class="`${lotteryCode} ball ball-99`"
                                v-for="(item,index) in 7" :key="index+'_4'">
                                <span> ? </span>
                            </div>
                        </template>
                        <template v-else>
                            <!--有多少先畫多少-->
                            <div :class="`${lotteryCode} ball ball-${parseInt(item).toString().substring(-1)}`"
                                v-for="(item,index) in reslotteryInfo2.last_period.draw.split(',')" :key="index+'_2'">
                                <span v-if="padLeftLottery.includes(lotteryCode)">{{item | padLeft(2)}}</span>
                            </div>
                            <div :class="`${lotteryCode} ball ball-99`"
                                v-for="(item,index) in 7- reslotteryInfo2.last_period.draw.split(',').length"
                                :key="index+'_3'">
                                <span> ? </span>
                            </div>
                        </template>
                    </template>
                </template>
            </template>
        </div>
    </div>
</template>
<script>
import {getLotteryInfo} from "@/api/api.js";
export default {
    props:{
        lastDraw:Array,
        lotteryCode:''
    },
    data(){
        return{
            padLeftLottery: ['azxy8', 'jisukuaile', 'lucky7daily', 'infinity8', 'infinity8ex', 'cannon20', 'cannon20ex', 'lucky5', 'lucky5ex', 'xglhc', 'kl8lhc'],
            padLeftLottery2: ['azxy10', 'azxy20', 'jisusaiche'],
            config: {
                time: '22:00:02',
                duringTime: '22:05:00',
                interval: 1,
                runNow: true,
                timeoutTimer: '',
                countNum: 0,
                continuousCallInterval: '',
            },
            reslotteryInfo2: ''
        }
    },
    components:{
    
    },
    methods: {
        getBussTopDataByTimer: async function(){
            if(this.config.runNow){
                await this.setTimer();
            }

            let nowTime = new Date().getTime();
            let timePoint = this.config.time.split(':').map((i) => parseInt(i));
            let duringTimePoint = this.config.duringTime.split(':').map((i) => parseInt(i));

            let recent = new Date().setHours(...timePoint);
            let recentDuringTime = new Date().setHours(...duringTimePoint);

            if (recent > nowTime) { //recent時間之前進入, 倒數計時(今天)
                var doRunTime = recent - nowTime;
                this.config.timeoutTimer = setTimeout(this.continuousCall, doRunTime);
            } else if (nowTime < recentDuringTime) { //recent 到 recentDuringTime 之間進入
                this.continuousCall();
            } else { //recentDuringTime之後時間進入, 倒數計時(隔天)
                if (recent <= nowTime) {
                    recent += 24 * 60 * 60 * 1000
                }
                var doRunTime = recent - nowTime;
                this.config.timeoutTimer = setTimeout(this.continuousCall, doRunTime);
            }
        },
        setTimer: async function(){
            let resBaseInfo = await getLotteryInfo(this.lotteryCode);
            const matchInfo = Object.keys(resBaseInfo.data).reduce((object, key) => {
                if (!key.match(/^(sgplhc)/)) {
                    object[key] = resBaseInfo.data[key]
                }
                return object
            }, {})
            this.reslotteryInfo2 = matchInfo;
        },
        continuousCall: async function(){
            let self = this;
            
            this.config.continuousCallInterval = setInterval(function () {
                if (self.config.countNum < 300) {
                    self.setTimer();
                    self.config.countNum++;
                } else {
                    self.config.countNum = 0;
                    clearInterval(self.config.continuousCallInterval);

                    //打完5分鐘, 計算下一次呼叫涵式時間
                    let nowTime = new Date().getTime();
                    let timePoint = self.config.time.split(':').map((i) => parseInt(i));
                    let recent = new Date().setHours(...timePoint);
                    //加上一天
                    recent += 24 *60 *60 * 1000
                    //剩餘時間
                    let doRunTime = recent - nowTime;
                    self.config.timeoutTimer = setTimeout(self.getBussTopDataByTimer, doRunTime);
                }
            }, 1000)
        }
    },
    created() {
        if(this.lotteryCode == 'lucky7daily'){
            this.getBussTopDataByTimer();
        }
    },
    beforeDestroy(){
        clearTimeout(this.config.timeoutTimer);
        clearInterval(this.config.continuousCallInterval);
    },
}
</script>
<style lang="scss" scoped>
.lottery_balls{
  grid-gap: 5px;
  padding-top:22px;

    .ball{
        font-size: 34px;
        text-align:center;
        color:#fff;
        margin:0 4px 25px;
    }
  .add{
    font-size: 40px;
    width: 22px;
    height: 59px;
    line-height: 59px;
    margin: 0 24px;
    color: #646363;
  }
}
    .ball-99{
        background: #505050
    }

</style>
