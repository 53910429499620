

export const longReminderTableConfig= {
    // =======================台湾大乐透====================
    twlhc:{
        changlong_two_sides:{
            header:"长龙出现条数",
            th:['期数','大','小','单','双','尾大','尾小','合单','合双','合大','合小']
        },
        changlong_draw_no_zodiac:{
            header:"生肖长龙出现条数",
            th:['期数','鼠','牛','虎','兔','龙','蛇','马','羊','猴','鸡','狗','猪']
        },
        changlong_draw_no_tail:{
            header:"尾数长龙出现条数",
            th:['期数','尾0','尾1','尾2','尾3','尾4','尾5','尾6','尾7','尾8','尾9']
        },
        changlong_two_sides_sp:{
            header:"长龙出现条数",
            th:['期数','大','小','单','双','尾大','尾小','合单','合双','合大','合小']
        },
        changlong_draw_no_zodiac_sp:{
            header:"生肖长龙出现条数",
            th:['期数','鼠','牛','虎','兔','龙','蛇','马','羊','猴','鸡','狗','猪']
        },
        changlong_draw_no_tail_sp:{
            header:"尾数长龙出现条数",
            th:['期数','尾0','尾1','尾2','尾3','尾4','尾5','尾6','尾7','尾8','尾9']
        },
        changlong_draw_no_color:{
            header:"色波长龙出现条数",
            th:['期数','红波','绿波','蓝波']
        },
        changlong_special_draw_no_color:{
            header:"色波长龙出现条数",
            th:['期数','红波','绿波','蓝波']
        },
        changlong_sum:{
            header:"总和长龙出现条数",
            th:['期数','大','小','单','双','七色波(红)','七色波(绿)','七色波(绿)','七色波(蓝)']
        }       
    },
    // =======================香港六合彩====================
    hklhc:{
        changlong_two_sides:{
            header:"长龙出现条数",
            th:['期数','大','小','单','双','尾大','尾小','合单','合双','合大','合小']
        },
        changlong_draw_no_zodiac:{
            header:"生肖长龙出现条数",
            th:['期数','鼠','牛','虎','兔','龙','蛇','马','羊','猴','鸡','狗','猪']
        },
        changlong_draw_no_tail:{
            header:"尾数长龙出现条数",
            th:['期数','尾0','尾1','尾2','尾3','尾4','尾5','尾6','尾7','尾8','尾9']
        },
        changlong_two_sides_sp:{
            header:"长龙出现条数",
            th:['期数','大','小','单','双','尾大','尾小','合单','合双','合大','合小']
        },
        changlong_draw_no_zodiac_sp:{
            header:"生肖长龙出现条数",
            th:['期数','鼠','牛','虎','兔','龙','蛇','马','羊','猴','鸡','狗','猪']
        },
        changlong_draw_no_tail_sp:{
            header:"尾数长龙出现条数",
            th:['期数','尾0','尾1','尾2','尾3','尾4','尾5','尾6','尾7','尾8','尾9']
        },
        changlong_draw_no_color:{
            header:"色波长龙出现条数",
            th:['期数','红波','绿波','蓝波']
        },
        changlong_special_draw_no_color:{
            header:"色波长龙出现条数",
            th:['期数','红波','绿波','蓝波']
        },
        changlong_sum:{
            header:"总和长龙出现条数",
            th:['期数','大','小','单','双','七色波(红)','七色波(绿)','七色波(绿)','七色波(蓝)']
        }       
    },
    qxc: {
        changlong_two_sides:{
            header:"长龙出现条数",
            th:['期数','大','小','单','双','尾大','尾小','合单','合双','合大','合小']
        },
    }
}