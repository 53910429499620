<template>
    <div> 
        <table>
            <tr class="header-title">
                <td class="time">时间</td>
                <td class="period">期号</td>
                <td class="drawNum">开奖结果</td>
                <td class="result">总和</td>
            </tr>
            <!--- 歷史結果列表 --->
            <tr v-for="(item,index) in dataList" :key="index" class="history_item">
                <td v-if="item.draw_time">{{item.draw_time[0] | formateDrawTimeAll }}</td>
                <td v-if="item.period_no">{{item.period_no[0]}}</td>
                <td>
                    <div class="ball-wrapper" v-if="item.ShowNumber">
                      <div :class="`${lotteryCode} ball ball-${parseInt(num)}`"  v-for="(num,index) in item.ShowNumber" :key="index">{{num}}</div>
                    </div>
                </td>
                 <td>
                    <div class="sum__wrapper" v-if="item.TotalSum">
                        <div class="text">{{item.TotalSum[0]}}</div>
                        <div class="text" :class="`${$options.filters.drawsFilter(item.TotalSum[1])}`" >{{item.TotalSum[1]}}</div>
                    </div>
                </td>
            </tr>
        </table>
    </div>
</template>
<script>
export default {
    props:{
        dataList:Array,
        lotteryCode:String
    }, methods:{
        getUseColor(string){
            if( string === '单' || string === '小' || string === '尾小'){
                return 'color-blue'
            }
            return 'color-red'
        },
    } 
}

</script>
<style lang="scss" scoped>

.header-title{
    background: #203d7f;
    color: #ffe6cc;
    height: 70px;
    td{
        border: 1px solid #a2bdec;
        font-size: 18px;
    }
    .time{
        width: 215px;
    }
    .period{
        width: 215px;
    }
    .drawNum{
        width: 567px;
    }
    .result{
        width: 446px;
    }
}


/** 歷史列表
******************************/
.history_item{
    height: 73px;
    font-size: 14px;
    color: #646363;
    &:nth-child(even) {
        background: #d0dce8
    }
    .ball-wrapper{
        display: flex;
        justify-content: center;
        .ball{
            color: #fff;
            margin-right: 20px;
        }
    }
     .sum__wrapper{
        display: flex;
        .text{
           
            width: 50%;
            &:last-child{
                font-weight: 600;
            }
        }
        
    } 
    td{
        border: 1px solid #a2bdec;
        font-size: 14px;
    }

}



</style>
