<template>
    <div class="lottery_next_time">
        <div>
            <div class="text">下期开奖</div>
            <div class="countdown">
                <div class="time">
                    <p>{{lotteryDrawTime[lotteryCode] | reciprocalDay}}</p>
                    <p class="word">天</p>
                </div>
                <div class="time">
                    <p>{{lotteryDrawTime[lotteryCode] | reciprocalHour}}</p>
                    <p class="word">時</p>
                </div>
                <div class="time">
                    <p>{{lotteryDrawTime[lotteryCode] | reciprocalMinute}}</p>
                    <p class="word">分</p>
                </div>
                <div class="time">
                    <p>{{lotteryDrawTime[lotteryCode] | reciprocalSecond}}</p>
                    <p class="word">秒</p>
                </div>
            </div>
        </div> 
    </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
    props:{
        lotteryCode:'',
    },
    computed: {
        ...mapState([
            "baseInfo",
            "lotteryDrawTime"
        ]),
    }
}
</script>
<style lang="scss" scoped>
.lottery_next_time{
    display: flex;
    align-items: center;
    .live_player{
        margin-top: 80px;
        margin-right: 40px;
        .live_img{
            width: 103px;
            height: 36px;
            margin: 0 auto;
            img{
                width: 100%;
            }
        }
        .title{
            text-align: center;
            font-size: 14px;
            color: #aab2ba;
            letter-spacing: 3px;
            margin: 3px 0 32px 0;
        }
    }
    .text{
        text-align: center;
        font-size: 18px;
        color: #0f2957;
        margin-bottom: 16px;
    }
    .countdown{
        display: flex;
        .time{
            padding-top: 20px;
            border-radius: 10px;
            border: 1px solid #aab2ba;
            width: 70px;
            height: 100px;
            margin-left: 5px;
            font-size:30px;
            color: #0f2957;
            text-align: center;
            box-sizing: border-box;
            .word{
                font-size:14px;
                color: #646363;
                margin-top: 5px;
            }
        }
    }
}

</style>
