<template>
    <div>
        <div class="result">
            <table width=100%>
                <tr class="table-header">
                    <td class="pos">位置</td>
                    <td class="hot_section">热</td>
                    <td class="warm_section">温</td>
                    <td class="cold_section">冷</td>
                </tr>
                <!--- 冷熱結果列表 --->
                <template v-if="allData.cold">
                    <tr class="result_item"  v-for="(item,index) in allData.cold.length" :key="index">
                        <td class="pos"> {{displayBallText[$route.params.name][index]}}</td>
                       
                        <td style="vertical-align: text-top;">
                            <div class="ball_wrapper">
                                <div class="ball hot" v-for="(num,i) in allData.hot[index]" :key="i">
                                  
                                    <span v-if="$route.params.name=='qxc'">{{num}}</span>
                                    <span v-else>{{num| padLeft(2)}}</span>
                                </div>
                            </div>
                        </td>
                        <td style="vertical-align: text-top;">
                            <div class="ball_wrapper">
                                <div class="ball warm" v-for="(num,i) in allData.warm[index]" :key="i">
                                    <span v-if="$route.params.name=='qxc'">{{num}}</span>
                                    <span v-else>{{num| padLeft(2)}}</span>
                                </div>
                            </div>
                        </td>
                        <td style="vertical-align: text-top;">
                            <div class="ball_wrapper">
                                <div class="ball cold" v-for="(num,i) in allData.cold[index]" :key="i">
                                    <span v-if="$route.params.name=='qxc'">{{num}}</span>
                                    <span v-else>{{num| padLeft(2)}}</span>
                                </div>
                            </div>
                        </td>
                    </tr>
                </template>
            </table>
            <!--- 說明 --->
            <div class="desc">
                <p>说明：</p>
                <p>统计最新
                    <span v-if="$route.params.name==='qxc'">50</span>
                    <span v-else>200</span>
                    期开奖
                </p>
                <p>热码 - 出现频繁、活跃的号码（8次以上）</p>
                <p>冷码 - 出现频率低甚至没有出现的号码（0-3次）</p>
                <p>温码 - 介于冷热之间的号码（4-7次）</p>
            </div>
        </div>
    </div>
</template>
<script>
import { getLotteryColdHot } from '@/api/api';
import { mapState, mapGetters, mapActions } from 'vuex';
export default {
    components:{
       
    },
    data(){
        return{
            allData:[],
            displayBallText:{
                twlhc:["正码 1","正码 2","正码 3","正码 4","正码 5","正码 6","特码 1"],
                hklhc:["正码 1","正码 2","正码 3","正码 4","正码 5","正码 6","特码 1"],
                qxc:["正码 1","正码 2","正码 3","正码 4","正码 5","正码 6","特码 1"],
                uspowerball:["正码 1","正码 2","正码 3","正码 4","正码 5","特码 1"],
                usmegamillions:["正码 1","正码 2","正码 3","正码 4","正码 5","特码 1"],
                euromillions:["正码 1","正码 2","正码 3","正码 4","正码 5","特码 1","特码 2"],
                ausaturdaylotto:["正码 1","正码 2","正码 3","正码 4","正码 5","正码 6","特码 1","特码 2"]
            },
            timer: null,

        }
    },
    watch: {
        '$route': {
            handler: function(to, from) {
                this.query();
            },
            //immediate: true
        } 
    },
    computed: {
        ...mapState([
            "baseInfo",
            "lotteryDrawTime"
        ]),
    },
    methods: {
        // 查詢所有資料
        async query(){
            try{
                const result = await getLotteryColdHot(this.$route.params.name);
                this.allData = result;
            }catch(e){
                
            }
            
        },  
    },
    mounted(){
        this.query(); 
        this.timer = setInterval(()=>{
            if(this.lotteryDrawTime[this.$route.params.name] <= 0) {
                this.query(); 
            }
        },1000);
    },
    destroyed() {
        clearInterval(this.timer);
    }
   
}
</script>
<style lang="scss" scoped>
.result{
    .table-header{
        width: 100%;
        height: 0.7rem;
        background-color: #c3d3e3;
        font-size: 0.17rem;
        line-height: .7rem;
        box-sizing: border-box;
        margin-top: 40px;
        td{
            border-right:1px solid #fff;
        }
        .pos{
            width: 14.7%; 
        }
        .hot_section,.cold_section{
            width: 24.11%;
        }
        .warm_section{
            width: 36.76%;
        }
    }
    .result_item{
        height: .70rem;
        font-size: .17rem;
        vertical-align:top;
        &:nth-child(odd) {
            background: #eaeaea
        }
        .ball_wrapper{
            display: flex;
            flex-wrap: wrap;
            .ball{
                margin:.1rem .1rem .1rem .1rem;   
                font-size:.3rem;
                color: #fff;
                &.hot{
                    background: #ff0000
                }
                &.warm{
                    background: #ffbf2e
                }
                &.cold{
                    background: #0050bc
                }
            }
        }
    }
    .desc{
        margin-top: 30px;
        font-size: 14px;
        color: #a2a2a2;
        line-height: 27px;
    }
}


</style>
