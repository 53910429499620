<template>
  <div class="copyRight">
    Ⓒ Copyright 2021 www.fu-wong.com All rights reserved 百万富翁资讯网 版权所有
  </div>
</template>

<script>
  export default {
    name: 'CopyRight',
    props: {
      msg: String
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .copyRight {
    width: 100%;
    height: 40px;
    background-color: #001236;
    
    font-size: 14px;
    line-height: 40px;
    text-align: center;
    color: #596c81;

    @media (max-width: $mobile_width) {
      height: auto;
    }
  }
</style>