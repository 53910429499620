import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Lottery from '../views/Lottery.vue'
import AboutUs from '../views/AboutUs.vue'
import Disclaimer from '../views/Disclaimer.vue'
import GameRule from '../views/GameRule.vue'
import ImagesLib from '../views/ImagesLib.vue'

Vue.use(VueRouter)

const noGoTopPath = [
	"AboutUs",
	"Disclaimer",
	"GameRule"
];

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/lottery/:name',
		name: 'Lottery',
		component: Lottery
	},
	{
		path: '/aboutUs/',
		name: 'AboutUs',
		component: AboutUs		
	},
	{
		path: '/disclaimer/',
		name: 'Disclaimer',
		component: Disclaimer		
	},
	{
		path: '/gamerule/',
		name: 'GameRule',
		component: GameRule	
	},
	{
		path: '/imageslib/:name',
		name: 'ImagesLib',
		component: ImagesLib
	},
	{
		path: '/mobile/',
		name: 'Home',
		component: () => import('../views/H5/Home.vue')
	},
	{
		path: '/mobile/news',
		name: 'News',
		component: () => import('../views/H5/News.vue')
	},
	{
		path: '/mobile/gamerule',
		name: 'GameRule',
		component: () => import('../views/H5/GameRule.vue')
	},
	{
		path: '/mobile/site',
		name: 'Sites',
		component: () => import('../views/H5/Sites.vue')
	},
	{
		path: '/mobile/about',
		name: 'About',
		component: () => import('../views/H5/About.vue')
	},
	{
		path: '/mobile/imageslib/:name',
		name: 'ImageLib',
		redirect: to => {
			return { path: `/mobile/lottery/${to.params.name}`, query:{currentTab:'imageLib'} }
		},
	},
	{
		path: '/mobile/lottery/:name',
		name: 'Lottery',
		component: () => import('../views/H5/Lottery.vue')
	},
]

const router = new VueRouter({
  	routes
})

router.beforeEach((to, from, next) => {
	if(noGoTopPath.find(element => element == to.name)) {
		if(noGoTopPath.find(element => element == from.name)) {
			return next();
		}		
	}   
	window.scroll(0, 0);
	return next();
})

//防止NavigationDuplicated
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function repalce(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

export default router
