<template>
    <div>
        <!----內部子選單 --->
        <div class="result_top">
            <!--- 子選單 --->
            <div class="lottoy_subMenu">
                <div 
                    v-for="(item,index) in subMenu" :key="index"
                    class="subMenu_btn"
                    :class="[`${$route.params.name}`,{'active':currentsubMenuIndex == index}]" 
                   
                    @click="changeSubMenu(index)">
                    {{item}}
                </div>    
            </div>
            <div class="right">
                <span class="statistics_period">统计期数</span> 
                <span 
                    class="period" 
                    :class="{'active':periodData[index] === period}"
                    v-for ="(item,index) in periodData"
                    @click="changePeriod(index)"
                    :key="index"
                >{{item}} 期
                </span>
            </div>
        </div>
        <!--- 顯示結果 --->
        <div id="myBarChart" :style="{width: '100%', height: '500px'}"></div>	
    </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { getLotteryStatistics } from '@/api/api';
export default {
    components:{
  
    },
    props:{
        currentTab:{
            type: String
        }
    },
    watch: {
        '$route': {
            handler: function(to, from) {
                this.currentsubMenuIndex =0;
                this.getAllData(); 
            },
            //immediate: true
        } 
    },
    data(){
        return{
            allData:[],
            periodData:[100,300,500],
            period:100,
            subMenu:[],
            currentsubMenuIndex:0,
            timer: null,
        }
    },
    computed: {
        ...mapState([
            "baseInfo",
            "lotteryDrawTime"
        ]),
    },
    methods: {  
        // 查詢所有資料
        async getAllData(){  
            try{
                const result = await getLotteryStatistics(this.$route.params.name);
                this.allData = result;
                this.getSubMenu(); 
            }catch(e){
                
            } 
        },  
        // 獲取全部子選單
        getSubMenu(){
            this.subMenu = this.$lotteryConfig[this.$route.params.name].mainMenu[this.currentTab].subMenu;
            this.outputData();
        },  
        // 獲取目前應對應資料
        outputData(){ 
            // this.dataInfor.mapApiKey = this.subMenu[this.currentsubMenuIndex].key;

            // 目前第幾顆球資料
            let currentData = this.allData[this.period][this.currentsubMenuIndex];
            let xAxisData = [];
            let seriesData = [];
            for(let key in currentData){
                xAxisData.push(key);
                seriesData.push(currentData[key]);
            }
            // 判斷是不是特碼，如果是特碼顏色長條圖要不一樣
            let color = this.subMenu[this.currentsubMenuIndex].indexOf('特码') > -1 ? '#eb9191' :'#91b4eb'

            let option = this.setBarOption(xAxisData,seriesData,color);
			let myChart = this.$echarts.init(document.getElementById('myBarChart'))
			// 绘制图表
			myChart.setOption(option)
        },
        // 長條圖設定
        setBarOption(xAxisData,seriesData,color){
            var option = {
                color: [color],
				xAxis: {
					name: "球号",
					type: 'category',
					data: xAxisData,
                    boundaryGap: false,
                    offset: 20,
                    nameLocation: "end",
                    nameTextStyle: {
                        align: "center",
                        verticalAlign: "top",
                        lineHeight: 80
                    },
                     axisLabel: {
                        fontSize: 16
                    },
                    nameGap: -15,
                    // min: -1,
                    nameTextStyle: {
                        align: "center",
                        verticalAlign: "top",
                        lineHeight: 120,
						fontSize: 14
						
                    },
					nameGap: 0,
				},
				yAxis: {
                    name: "次数",
					type: 'value',
                    nameLocation: "end",
                    nameTextStyle: {
                        verticalAlign: "bottom",
						fontSize: 14,
                        padding: [0, 37, 0, 0]
                    },
                     axisLabel: {
                        fontSize: 16
                    },
                    nameGap: 30,
                    offset: 20,
				},
                 grid: {
                    left: 35,
                    right:35,
                    containLabel: true
                },
				series: [{
					data: seriesData,
					type: 'bar',
                    barMaxWidth :"30px"
				}]
			};
            return option;
        },
       
        // 切換子選單
        changeSubMenu(index){
            this.currentsubMenuIndex = index;
            this.outputData();

        },
        // 切換期數
        changePeriod(index){ 
            this.period = this.periodData[index];
            this.outputData();
        }
 
    },
    mounted(){
        this.getAllData();
        this.timer = setInterval(()=>{
            if(this.lotteryDrawTime[this.$route.params.name] <= 0) {
                this.getAllData(); 
            }
        },1000)   
    },
    destroyed() {
        clearInterval(this.timer);
    }
   
}
</script>
<style lang="scss" scoped>
.result_top{
    align-items: center;
    display: flex;
    justify-content: space-between;
    .right{
        font-size:14px ;
        .statistics_period{
           color: #646363;
        }
        .period{
            cursor: pointer;
            margin-left: 32px;
            &.active{
                color: #14459c;
                font-weight: 800;
            }
        }
    }
}

// 子選單
.lottoy_subMenu{
    box-sizing: border-box;
    display: flex;
    .subMenu_btn{
        cursor: pointer;
        display: inline-block;
        color: #5a99c6;
        font-size: 14px;
        width: 90px;
        height: 32px;
        border: 1px solid #5a99c6;
        line-height: 32px;
        text-align: center;
        border-radius: 50px;
        margin-right: 16px;
        &:last-child{
            color: #c65a5a;
            border: 1px solid #c65a5a;
        }
       
        &:hover{
            color: #ffe6cc !important;
            background: #001236;
        }
        &.active{
            color: #ffe6cc;
            background: #001236 !important;
        }
       
    }
}
#myBarChart{
    margin-top: 40px;
}

// 歐洲百萬和澳大利亞百萬，特碼1和特碼2 都要紅色
.euromillions:nth-last-child(2),.ausaturdaylotto:nth-last-child(2){
    color: #c65a5a;
    border: 1px solid #c65a5a;
}
</style>
