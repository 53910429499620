<template>
    <div class="lhc">
        <div class="lottery_balls">
            <div :class="`${lotteryCode} ball ball-${getIntNumStyle(item)}`"
                 v-for="(item,index) in lastDraw.slice(0, 6)"
                :key="index">{{item | padLeft(2)}}
            </div>
            <div class="add">+</div>
            <div :class="`${lotteryCode} ball ball-${parseInt(lastDraw[lastDraw.length-1])}`">
                {{lastDraw[lastDraw.length-1] | padLeft(2)}}
            </div>  
        </div>
        <div class="lottery_sx" v-if="lastperiodInfo">
            <div v-for="(item, index) in lastperiodInfo.display_info.zodiac" :key="'zodiac' + index"  class="item">
                {{item}}</div>
        </div>
        <div class="lottery_wx" v-if="lastperiodInfo">
            <div v-for="(item, index) in lastperiodInfo.display_info.five_elements" :key="'five_elements' + index"  class="item">
                {{item}}</div>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        lastDraw:Array,
        lotteryCode:'',
        lastPeriod: '',
        lastperiodInfo: null,
    },
    components:{
    
    },
     methods: {
        getIntNumStyle(num){
            return parseInt(num)
        }
    },
    mounted() {
    }
    // data() {
    //     return {
    //         lastDrawNumber:'', 
    //     }
    // },
}
</script>
<style lang="scss" scoped>
.lottery_balls{
    padding-top:22px ;
    .ball{
        font-size: 34px;
        margin-right: 8px;
    }
}
.lottery_sx{
   
}
.lottery_wx{
    margin-top: 24px;
}
.lottery_sx,.lottery_wx{
    display: flex;
    font-size: 17px;
    color: #646363;
    .item{
        width: 60px;
        text-align: center;
        margin-right: 8px;
        &:nth-child(6){
            margin-right: 0;
        }  
        &:last-child{
            margin-left: 80px;
        }
    }
}
.add{
    font-size:40px;
    width: 22px;
    height: 59px;
    line-height: 59px;
    margin: 0 24px;
    color: #646363;
}
.lhc{
    .ball:nth-child(6){
        margin-right: 0;
    }         
} 
</style>
