<template>
    <div> 
        <table>
            <tr>
                <td rowspan="2" width="193">时间</td>
                <td rowspan="2" class="change_Type" width="236">
                    <span 
                        class="btn zodiac" 
                        :class ="{'active':isShowSx}"
                        @click="changeType('sx')">
                        显示生肖
                    </span>
                    <span 
                        class="btn fivElements"
                        :class ="{'active':!isShowSx}"
                        @click="changeType('wx')">
                        显示五行
                    </span>
                </td>
                <td rowspan="2" v-if="lotteryCode === 'kl8lhc'">回放</td>
                <td rowspan="2" width="73">特码</td>
                <td colspan="6" width="73">总和</td>
                <td colspan="6">特码</td>
            </tr>
            <tr>
                <td>总分</td>
                <td>总数单双</td>
                <td>总数大小</td>
                <td>七色波</td>
                <td>一肖量</td>
                <td>尾数量</td>
                <td>单双</td>
                <td>大小</td>
                <td>合单双</td>
                <td>合大小</td>
                <td>尾大小</td>
                <td>五行</td>
            </tr>
            <!--- 歷史結果列表 --->

          <tr v-for="(item,index) in dataList" :key="index" class="history_list">
            <td class="time" v-if="item.period_no">{{item.period_no[0]}}期<div class="date" v-if="item.draw_time"> {{item.draw_time[0] | formateDrawTimeDate}}</div></td>
            <td>
              <div class="displayType">
                <div class="ballWrapper">
                  <div :class="`${lotteryCode} ball ball-${parseInt(num)}`"
                       v-for="(num,index) in item.ShowNumber.slice(0,6)"
                       :key="index">{{num | padLeft(2)}}</div>
                </div>
                <div class="textWrapper" v-if="item.ShowAnimal">
                  <div class="text"
                       v-for="(sx,index) in item.ShowAnimal.slice(0,6)"
                       :key="index+'-sx'"
                       v-show="isShowSx"
                  >{{sx}}
                  </div>
                  <div class="text"
                       v-for="(wx,index) in item.ShowFiveElements.slice(0,6)"
                       :key="index+'-wx'"
                       v-show="!isShowSx"
                  >{{wx}}
                  </div>
                </div>
              </div>
            </td>
            <td v-if="lotteryCode === 'kl8lhc'">
              <a @click.prevent='playDraw(item)' style='cursor:pointer;border-radius:2px;background:#7ab9ff;color:#001336;padding:5px 2px;'>
                回放
              </a>
            </td>
            <td class="border-left border-right" align="center">
              <div class="item_wrapper">
                <template v-if="lotteryCode == 'lucky7daily'"><!--只針對英國六修正-->
                    <div v-if="item.ShowNumber[6]" :class="`${lotteryCode} ball ball-${parseInt(item.ShowNumber[6])}`">{{item.ShowNumber[6] | padLeft(2)}}</div>
                </template>
                <template v-else>
                    <div :class="`${lotteryCode} ball ball-${parseInt(item.ShowNumber[6])}`">{{item.ShowNumber[6] | padLeft(2)}}</div>
                </template>
                <div class="text" v-if="item.ShowAnimal" v-show="isShowSx">
                  {{item.ShowAnimal[6]}}
                </div>
                <div class="text" v-if="item.ShowFiveElements" v-show="!isShowSx">
                  {{item.ShowFiveElements[6]}}
                </div>
              </div>
            </td>
            <td class="total-score" v-if="item.TotalScore">{{item.TotalScore[0]}}</td>
            <td v-if="item.TotalSingleDouble"><span :class="getUseColor(item.TotalSingleDouble[0])">{{item.TotalSingleDouble[0]}}</span></td>
            <td v-if="item.TotalBigSmall"><span :class="getUseColor(item.TotalBigSmall[0])">{{item.TotalBigSmall[0]}}</span></td>
            <td v-if="item.SevenColor" :style="{'color':`${getUseColorClour(item.SevenColor[0])}`}">
              {{item.SevenColor[0]}}
            </td>
            <td v-if="item.AnimalCount">{{item.AnimalCount[0]}}</td>
            <td class="border-right" v-if="item.TailCount">{{item.TailCount[0]}}</td>
            <td v-if="item.SpecialSingleDouble"><span :class="getUseColor(item.SpecialSingleDouble[0])">{{item.SpecialSingleDouble[0]}}</span></td>
            <td v-if="item.SpecialBigSmall"><span :class="getUseColor(item.SpecialBigSmall[0])">{{item.SpecialBigSmall[0]}}</span></td>
            <td v-if="item.SpecialSumSingleDouble"><span :class="getUseColor(item.SpecialSumSingleDouble[0])">{{item.SpecialSumSingleDouble[0]}}</span></td>
            <td v-if="item.SpecialSumBigSmall"><span :class="getUseColor(item.SpecialSumBigSmall[0])">{{item.SpecialSumBigSmall[0]}}</span></td>
            <td v-if="item.SpecialTailBigSmall"><span :class="getUseColor(item.SpecialTailBigSmall[0])">{{item.SpecialTailBigSmall[0]}}</span></td>
            <td style="color:#0380d8" v-if="item.SpecialFiveElements">{{item.SpecialFiveElements[0]}}</td>
          </tr>
        </table>
    </div>
    
    
</template>
<script>
export default {
    props:{
        dataList:Array,
        lotteryCode:String
    },
    data(){
        return{
            isShowSx:true,  
        }
    },
    methods:{
        changeType(type){
            if(type === 'sx'){
                this.isShowSx = true;
            }else{
                this.isShowSx = false;
            }
        },
        getSpecialNum(string){
          return string.split(',').pop();
        },
        getUseColor(string){
          if( string === '单' || string === '小'){
            return 'color-blue'
          }
          return 'color-red'
        },
        getUseColorClour(string){
          switch(string)
          {
            case '红波':
              return 'red';
            case '绿波':
              return '#23b439';
            case '蓝波':
              return '#0380d8';
            case '和局':
              return '#646363';
            default:
              return 'red'
          }
        },
      playDraw(item){
        const [ year, month, date ] = item.draw_time.join().split(' ')[0].split('/')
        const periodNo = item.period_no[0];
        const drawNumber = item.ShowNumber;
        const showAnimal = item.ShowAnimal;
        const showFiveElements = item.ShowFiveElements;
        this.$emit('openDrawingVideo', {year, month, date, periodNo, drawNumber, showAnimal, showFiveElements});
      }
    }
}

</script>
<style lang="scss" scoped>

/** 歷史列表title
******************************/

tr:nth-child(odd) {
    background: #d0dce8
}
tr:nth-child(1),tr:nth-child(2){
    background: #203d7f;
    color: #ffe6cc;
    height: 32px;
    td{
        border: 1px solid #a2bdec;
        font-size: 18px;
    }
}
tr:nth-child(1){
    .change_Type{
        .btn{
            cursor: pointer;
            display: inline-block;
            color: #59626c;
            &.active{
                color: #ffe6cc;
            }
        }
        .btn:nth-child(2){
            margin: 0 15px;
        }
    }
}
/** 歷史列表
******************************/
.history_list{
    height: 73px;
    color:#646363;
    .time{
        .date{
            margin-top: 5px;
        }
    }
    .displayType{
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        .ballWrapper{
            display: flex;
            justify-content: space-around;
            .ball{
                @include ballSize(24px);     
            }
           
        }
        .textWrapper{
            margin-top: 5px;
            display: flex;
            justify-content: space-around;
        }
    }
    td{
        border: 1px solid #a2bdec;
        font-size: 14px;
    }
}

.item_wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    .ball{
        line-height: 24px;
        color: #fff;
    }
    .text{
        margin-top: 5px;
    } 
}

.total-score{
    color: #df0d15;
}
.color-red{
    color: #df0d15
}
.color-blue{
    color: #0380d8;
}

.border-left{
    border-left:1px solid #3d4852 ;
}
.border-right{
    border-right:1px solid #3d4852 ;
}

</style>
