<template>
    <div style="position: relative;">
        <div class="result_top">
            <!--- 子選單 --->
            <div class="lottoy_subMenu">
                <div v-if="subMenu.length>1">
                    <div 
                        v-for="(item,index) in subMenu" :key="index"
                        class="subMenu_btn"
                        :class="{
                        'blue_color':item.name == '正码长龙',
                        'red_color':item.name == '特码长龙',
                        'black_color':item.name == '总和长龙', 
                        'active':currentsubMenuIndex == index}" 
                        @click="changeSubMenu(index)">
                        {{item.name}}
                    </div>
                </div>
            </div>
        </div>
        <div class="right">
            <span class="statistics_period">统计期数</span> 
            <span class="period first" :class="dataLength == '100' ? 'enable' : ''" @click="setDataLength('100')">100 期</span>
            <span class="period" :class="dataLength == '300' ? 'enable' : ''" @click="setDataLength('300')">300 期</span>
            <span class="period" :class="dataLength == '500' ? 'enable' : ''" @click="setDataLength('500')">500 期</span>
        </div>
        <!-- 沒子選單的空白間格 -->
        <div v-if="level1Menu.length <=1 && level2Menu.length <= 1" style="height: 17px" ></div>
        <!-- 子選單-level1 --->
        <div v-if="level1Menu.length > 1" 
            class="level1Menu_wrapper" 
            :class="{'useBorderBotton':level2Menu.length > 0}">
            <div
                class="level1Menu_btn"
                :class="{'red_color':item.name == '特码', 'active':currentLevel1MenuIndex == index}"
                v-for="(item,index) in level1Menu"
                :key="index" 
                @click="changeLevel1Menu(index)"
                >
                {{item.name}}
            </div>
        </div>
        <!-- 子選單-level2 --->
        <div v-if="level2Menu.length > 1" class="level2Menu_wrapper">
            <div
                class="level2Menu_btn"
                :class="{'active':currentLevel2MenuIndex == index}"
                @click="changeLevel2Menu(index)"
                v-for="(item,index) in level2Menu"
                :key="index" 
                >
                {{item.name}}
            </div>
        </div>
        <!--- 六合彩 ---> 
        <Qxc v-if="lotteryCode=='qxc'" :dataInfor="dataInfor" :ballIndex="ballIndex" :dataLength="dataLength"/>
        <Hklhc v-if="lotteryCode=='xglhc'" :dataInfor="dataInfor" :ballIndex="ballIndex" :dataLength="dataLength"/>
        <TwLhc v-if="lotteryCode=='twlhc'" :dataInfor="dataInfor" :ballIndex="ballIndex" :dataLength="dataLength"/>
    </div>
</template>
<script>
import {longReminderTableConfig} from "@/assets/longReminderTableConfig.js"
import Hklhc from "@/components/lottory_result/LongReminder/hklhc";
import TwLhc from "@/components/lottory_result/LongReminder/twlhc";
import Qxc from "@/components/lottory_result/LongReminder/qxc";

export default {
    components:{
        Hklhc,
        TwLhc,
        Qxc
    },
    props:{
        currentTab:{
            type: String
        },
        lotteryCode: {
            type: String,
            default: "xglhc",
        },
        // useLevel1BottomLine:{
        //     type: Boolean,
        //     default:true
        // }
    },
    data(){
        return{ 
            subMenu:[],
            periods:100,
            currentsubMenuIndex:0,
            currentLevel1MenuIndex:0,
            currentLevel2MenuIndex:0,
            level1Menu:[],
            level2Menu:[],
            dataInfor:{
                dataList:[],
                dataHeader:[],
                dataTitle:'',
                mapApiKey:'',
                allPeriod:9,
            },
            ballIndex: 0,
            dataLength: "100",
        }
    },
    methods: {
        query(){
            // 發請求
            this.outputData()
        },
      
        // 獲取目前應對應資料
        outputData(){ 
            let  lottoryCode = this.$route.params.name;
            let mapApiKey ='';
            // switch (lottoryCode) {
            //     case 'twlhc':
            //     　
            //     　break;
            //     case 'qxc':
            //     　
            //     　break;
            //     default:
            //     　console.log("nononono")
            // }
            
            switch (this.currentsubMenuIndex) {
                // 正碼長龍
                case 0:
                   
                    mapApiKey = this.subMenu[this.currentsubMenuIndex].level2[this.currentLevel2MenuIndex].key;
                    this.dataInfor.mapApiKey = mapApiKey;
                    this.dataInfor.dataTitle = longReminderTableConfig[lottoryCode][mapApiKey].header;
                   
                　  break;
                // 特码长龙
                case 1:
                    mapApiKey = this.subMenu[this.currentsubMenuIndex].level1[this.currentLevel1MenuIndex].key;
                    this.dataInfor.mapApiKey = mapApiKey;
                    this.dataInfor.dataTitle = longReminderTableConfig[lottoryCode][mapApiKey].header;
                    break;
                // 总和长龙
                case 2:
                    mapApiKey = this.subMenu[this.currentsubMenuIndex].level1[this.currentLevel1MenuIndex].key;
                    this.dataInfor.mapApiKey = mapApiKey;
                    this.dataInfor.dataTitle = longReminderTableConfig[lottoryCode][mapApiKey].header;
                　  break;
                default:
            }  
        },
        // 獲取全部子選單
        getSubMenu(){
            this.subMenu = this.$lotteryConfig[this.$route.params.name].mainMenu[this.currentTab].subMenu;
            this.getLevelMenu();
        }, 
        // 切換子選單
        changeSubMenu(index){
            this.currentsubMenuIndex = index;
            this.currentLevel1MenuIndex = 0;
            this.currentLevel2MenuIndex = 0;
            this.ballIndex = 0;
            this.getLevelMenu();
            this.outputData();
        },

        // 切換 currentLevel1 選單
        changeLevel1Menu(index){
            this.currentLevel1MenuIndex = index;

            if(typeof index == "number") {
                this.ballIndex = index;
            }
            this.outputData();
        },

        // 切換 currentLevel2 選單
        changeLevel2Menu(index){
            this.currentLevel2MenuIndex = index;
            this.outputData();
        },

        // 判斷該選單是否有 2，3級 選單
        getLevelMenu(){
            // 先清空
            this.level1Menu = [];
            this.level2Menu = [];
            if(this.subMenu[this.currentsubMenuIndex].level1){
                this.level1Menu = this.subMenu[this.currentsubMenuIndex].level1;
            }
            if(this.subMenu[this.currentsubMenuIndex].level2){
                this.level2Menu = this.subMenu[this.currentsubMenuIndex].level2;
            }
            this.query();            
        },
        //切換資料筆數
        setDataLength(value) {
            this.dataLength = value;
        },
    },
    mounted(){
        this.getSubMenu();
        this.query();
    }
   
}
</script>

<style lang="scss" scoped>
.result_top{
    align-items: center;
    display: flex;
    justify-content: space-between;
}
.right{
    font-size:14px ;
    position: absolute;
    right: 0px;
    top: 6px;
    .statistics_period{
        color: #646363;
    }
    .period{
        margin-left: 22px;
        color: #aab2ba;
        cursor: pointer;
        &.enable{
            color: #0f2957;
        }
        &.first{
            margin-left: 42px;
        }
    }
}
.table-header{
    width: 100%;
    height: 0.7rem;
    background-color: #001236;
    font-size: 0.17rem;
    display: flex;
    line-height: .7rem;
    padding: 0 .32rem;
    box-sizing: border-box;
    justify-content: space-between;
    .left-text{
        color: #7f8993;
    }   
    .right-text{
        color: #ffe6cc;
    }
}
.wrapper{
    overflow-x: scroll;
}
td{
    font-size: .17rem;
    line-height: .24rem;
    min-width: .4rem;
}
td:nth-child(odd){
    padding: .1rem 0;
    background: #eaeaea;
}


// 子選單
.lottoy_subMenu{
    box-sizing: border-box;
    display: flex;
    .subMenu_btn{
        cursor: pointer;
        display: inline-block;
        color: #2e2e2e;
        font-size: 14px;
        width: 90px;
        height: 32px;
        border: 1px solid #2e2e2e;
        line-height: 32px;
        text-align: center;
        border-radius: 50px;
        margin-right: 16px;
        &:hover{
            color: #c65a5a;
            border: 1px solid #c65a5a;
            background: #fff;
        }
        &.red_color{
            color: #c65a5a;
            border-color: #c65a5a;
        }
        &.blue_color{
            color: #5a99c6;
            border-color: #5a99c6;
        }
        &.black_color{
            color: #59626c;
            border-color: #59626c;
        }
        &.active{
            color: #ffe6cc;
            background: #001236;
        }        
    }
}

// level1 選單
.level1Menu_wrapper{
    width: fit-content;
    &.useBorderBotton{
        border-bottom: 1px solid #ccc;
    }
    .level1Menu_btn{
        padding: 17px 0;
        cursor: pointer;
        display: inline-block;
        color: #5a99c6;
        font-size: 14px;
        width: 92px;
        line-height: 32px;
        text-align: center;
        margin-right: 16px;
        &.red_color{
            color: #c65a5a;
        }
        &:hover{
            color: #001236;
            background: #fff;
        }
        &.active{
            color: #001236; 
        }
    }
}

// level2 選單
.level2Menu_wrapper{
    width: fit-content;
    .level2Menu_btn{
        padding: 17px 0;
        cursor: pointer;
        display: inline-block;
        color: #aab2ba;
        font-size: 14px;
        width: 92px;
        line-height: 32px;
        text-align: center;
        margin-right: 16px;
        &:hover{
            color: #001236;
            background: #fff;
        }
        &.active{
            color: #001236; 
        }        
    }
}
</style>
