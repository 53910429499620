<template>
  <div class="footer" @click="$emit('secondLevel','')">
        <router-link class="item title" active-class="active" exact to="/mobile">
          首页
        </router-link>
        <router-link class="item title" active-class="active" exact to="/mobile/news">
          最新消息
        </router-link>
        <router-link class="item title" active-class="active" :class="setUseActive(this.currentHistoryName)" exact to="/mobile/lottery/qxc">
         开奖历史
        </router-link>
        <router-link class="item title" active-class="active" exact to="/mobile/gamerule">
          游戏规则
        </router-link>
        <router-link class="item title" active-class="active" exact to="/mobile/site">
          彩票官网
        </router-link>
        <router-link class="item title" active-class="active" exact to="/mobile/about">
          我的
        </router-link>
  </div>
</template>
<script>
import {lotteryConfig} from "@/assets/lotteryConfig.js";
export default {
    data(){
        return{
            currentHistoryName:'',
            lotteryConfig: lotteryConfig,
        }
    },
    watch: {
        '$route': {
            handler: function(to, from) {
               this.currentHistoryName = to.params.name;
            },
        } 
    },
    methods:{
        setUseActive(string){
            if(string == undefined || lotteryConfig[string] == undefined) return;
            return 'active';
        },
    },
}
</script>
<style lang="scss" scoped>
.footer{
  position: fixed;
  width: 100%;
  background-color:#001236 ;
  bottom: 0;
  box-shadow: 0px -2px 14px -3px rgba(0,0,0,0.48);
  height: 62px;
  z-index: 2;
  display: flex;
  justify-content: space-around;
      .item{
        color: #727272;
        display: flex;
        padding-bottom:10px;
        align-items: flex-end;
        display: -webkit-flex;
        -webkit-align-items: flex-end;
        justify-content: center;
        font-size: 12px;
        background-size: auto;
        background-position: center 10px;
        background-repeat: no-repeat;

        flex:1;
        &:nth-child(1){
          background-image: url("~@/img/H5/home-1.png");
        }
        &:nth-child(2){
          background-image: url("~@/img/H5/news-1.png");
        }
        &:nth-child(3){
          background-image: url("~@/img/H5/statistics-1.png");
        }
        &:nth-child(4){
          background-image: url("~@/img/H5/games-1.png");
        }
        &:nth-child(5){
          background-image: url("~@/img/H5/global-1.png");
        }
        &:nth-child(6){
          background-image: url("~@/img/H5/user-1.png");
        }

        &.active{
            color: #f2cba6;
          &:nth-child(1){
            background-image: url("~@/img/H5/home-2.png");
          }
          &:nth-child(2){
            background-image: url("~@/img/H5/news-2.png");
          }
          &:nth-child(3){
            background-image: url("~@/img/H5/statistics-2.png");
          }
          &:nth-child(4){
            background-image: url("~@/img/H5/games-2.png");
          }
          &:nth-child(5){
            background-image: url("~@/img/H5/global-2.png");
          }
          &:nth-child(6){
            background-image: url("~@/img/H5/user-2.png");
          }
        }
      }
}

</style>